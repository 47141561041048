import React, { useCallback, useEffect } from "react";
import { H1 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { URL_DT_GUIDE_START, URL_DT_TIMER } from "../../../config/urls";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { Card } from "../../../components/card";
import quantisal_step6 from "./quantisal_step6.png";
import quantisal_step7 from "./quantisal_step7.png";
import quantisal_step8 from "./quantisal_step8.png";
import quantisal_step9 from "./quantisal_step9.png";

export const DTQuantisalPostRoute = () => {
    const [goToGuideStart, goBack] = useNavigator(
        URL_DT_GUIDE_START,
        URL_DT_TIMER
    );
    const { t } = useTranslation();

    const {
        addPageLoadMetric,
        addCustomEventMetric,
        addGoBackEventMetric,
    } = useAdobeAnalytics();

    useEffect(() => {
        addPageLoadMetric("post drug test video");
    }, []);

    const back = useCallback(() => {
        addGoBackEventMetric();
        goBack();
    }, [goBack, addCustomEventMetric]);

    const next = useCallback(() => {
        goToGuideStart();
    }, [goToGuideStart]);

    return (
        <Col margin="3rem 0 0 0" gridGap="2rem">
            <H1 fontSize="T500">{t("dt-video-post-title")}</H1>
            <div className="quantisal-post-label">
                <div className="step6">
                    <Row justifyContent="center">
                        <Card
                            title={t("dt-quantisal-post-step6-title")}
                            text={t("dt-quantisal-post-step6")}
                            imageHref={quantisal_step6}
                        />
                    </Row>
                </div>
                <div className="step7">
                    <Card
                        title={t("dt-quantisal-post-step7-title")}
                        text={t("dt-quantisal-post-step7")}
                        imageHref={quantisal_step7}
                    />
                </div>
                <div className="step8">
                    <Card
                        title={t("dt-quantisal-post-step8-title")}
                        text={t("dt-quantisal-post-step8")}
                        imageHref={quantisal_step8}
                    />
                </div>
                <div className="step9">
                    <Row justifyContent="center">
                        <Card
                            title={t("dt-quantisal-post-step9-title")}
                            text={t("dt-quantisal-post-step9")}
                        imageHref={quantisal_step9}
                        />
                    </Row>
                </div>
            </div>
            <Row justifyContent="flex-end" gridGap="1rem" margin="3rem 0 0 0">
                <Button onClick={back}>{t("common-back")}</Button>
                <Button onClick={next} variant={ButtonVariant.Primary}>
                    {t("common-next")}
                </Button>
            </Row>
        </Col>
    );

};

