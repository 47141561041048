import React, { useCallback, useEffect } from "react";
import { H1 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { URL_DT_GUIDE_START, URL_DT_TIMER } from "../../../config/urls";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { Card } from "../../../components/card";
import post_step1 from "./post_step1.png";
import post_step2 from "./post_step2.png";
import post_step3 from "./post_step3.png";
import post_step4 from "./post_step4.png";

export const DTVideoPostRoute = () => {
  const [goToGuideStart, goBack] = useNavigator(
    URL_DT_GUIDE_START,
    URL_DT_TIMER
  );
  const { t } = useTranslation();
 

  const {
    addPageLoadMetric,
    addCustomEventMetric,
    addGoBackEventMetric,
  } = useAdobeAnalytics();

  useEffect(() => {
    addPageLoadMetric("post drug test video");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addCustomEventMetric]);

  const next = useCallback(() => {
    goToGuideStart();
  }, [goToGuideStart]);

 
    return (
      <Col margin="3rem 0 0 0" gridGap="2rem">
        <H1 fontSize="T500">{t("dt-video-post-title")}</H1>
        <div className="video-post-label">
          <div className="step1">
            <Row justifyContent="center">
              <Card
                title={t("dt-video-post-step1-title")}
                text={t("dt-video-post-step1")}
                imageHref={post_step1}
              />
            </Row>
          </div>
          <div className="step2">
            <Card
              title={t("dt-video-post-step2-title")}
              text={t("dt-video-post-step2")}
              imageHref={post_step2}
            />
          </div>
          <div className="step3">
            <Card
              title={t("dt-video-post-step3-title")}
              text={t("dt-video-post-step3")}
              imageHref={post_step3}
            />
          </div>
          <div className="step4">
            <Row justifyContent="center">
              <Card
                title={t("dt-video-post-step4-title")}
                text={t("dt-video-post-step4")}
                imageHref={post_step4}
              />
            </Row>
          </div>
        </div>
        <Row justifyContent="flex-end" gridGap="1rem" margin="3rem 0 0 0">
          <Button onClick={back}>{t("common-back")}</Button>
          <Button onClick={next} variant={ButtonVariant.Primary}>
            {t("common-next")}
          </Button>
        </Row>
      </Col>
    );
  
  

};

