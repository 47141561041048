import React, { useCallback, useState } from "react";
import { H2, Label } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import "./styles.scss";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import {
  validatePhone,
  validatePhonesMatch,
} from "../../../utility/validation";

interface EditPhoneNumberDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onPhoneNumberChange: (phoneNumber: string) => void;
}

export const EditPhoneNumberDialog = ({
  isOpen,
  onClose,
  onPhoneNumberChange,
}: EditPhoneNumberDialogProps) => {
  const [newPhone, setNewPhone] = useState("");
  const [confirmPhone, setConfirmPhone] = useState("");
  const [newPhoneValid, setNewPhoneValid] = useState(true);
  const [confirmPhoneValid, setConfirmPhoneValid] = useState(true);

  const { t } = useTranslation();

  const reset = useCallback(() => {
    setNewPhone("");
    setConfirmPhone("");
    setNewPhoneValid(true);
    setConfirmPhoneValid(true);
    onClose();
  }, []);

  const onNewPhoneChange = useCallback((e) => {
    const newPhone = e.target.value;
    const newPhoneParsed = validatePhone(newPhone);
    setNewPhone(newPhone);
    setNewPhoneValid(!!newPhoneParsed);
  }, []);

  const onConfirmPhoneChange = useCallback(
    (e) => {
      const confirmPhone = e.target.value;
      const isConfirmPhoneValid = validatePhonesMatch(newPhone, confirmPhone);
      setConfirmPhone(confirmPhone);
      setConfirmPhoneValid(!!isConfirmPhoneValid);
    },
    [newPhone]
  );

  const changePhoneNumber = useCallback(() => {
    const newPhoneParsed = validatePhone(newPhone);
    const isConfirmPhoneValid = validatePhonesMatch(newPhone, confirmPhone);

    if (!newPhoneParsed || !isConfirmPhoneValid) {
      setNewPhoneValid(!!newPhoneParsed);
      setConfirmPhoneValid(isConfirmPhoneValid);
      return false;
    }

    onPhoneNumberChange(newPhoneParsed);
    reset();
  }, [newPhone, confirmPhone]);

  return (
    <Modal isOpen={isOpen} close={reset}>
      <Col gridGap="2rem">
        <H2>{t("dialog-updatePhone-title")}</H2>
        <Row>
          <div className="phone-form">
            <div className="newPhoneLabel">
              <Label htmlFor="newPhone">
                {t("dialog-updatePhone-newPhone")}
              </Label>
            </div>
            <div className="newPhone">
              <Input
                id="newPhone"
                autoComplete="off"
                type="text"
                width="100%"
                error={!newPhoneValid}
                placeholder={t("dialog-updatePhone-phonePlaceholder")}
                value={newPhone}
                onChange={onNewPhoneChange}
              />
            </div>
            <div className="phoneValidation">
              {!newPhoneValid && (
                <InputFooter warning={true}>
                  {t("dialog-updatePhone-phoneValidation")}
                </InputFooter>
              )}
              <br />
            </div>
            <div className="confirmPhoneLabel">
              <Label htmlFor="confirmPhone">
                {t("dialog-updatePhone-confirmPhone")}
              </Label>
            </div>
            <div className="confirmPhone">
              <Input
                id="confirmPhone"
                autoComplete="off"
                type="text"
                width="100%"
                error={!confirmPhoneValid}
                placeholder={t("dialog-updatePhone-phonePlaceholder")}
                value={confirmPhone}
                onChange={onConfirmPhoneChange}
              />
            </div>
            <div className="confirmPhoneValidation">
              {!confirmPhoneValid && (
                <InputFooter warning={true}>
                  {t("dialog-updatePhone-confirmPhoneValidation")}
                </InputFooter>
              )}
            </div>
          </div>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onClose}>{t("common-cancel")}</Button>
          <Button
            onClick={changePhoneNumber}
            variant={ButtonVariant.Primary}
            data-testid="confirm-phone-number"
          >
            {t("common-confirm")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
