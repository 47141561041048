import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  URL_TEST_CSRF,
  URL_MONITOR_SIGN_OUT,
  URL_CANDIDATE_LOG_IN,
  URL_DT,
  URL_CLEANING,
  URL_TY,
  URL_MONITOR_SIGN_IN,
  URL_MONITOR_SCAN_QR_CODE,
  URL_MONITOR_SETTINGS,
} from "../../config/urls";

import { MonitorSignInRoute } from "../../routes/monitor/sign-in";
import { Settings } from "../../routes/monitor/settings";
import { InternalRoute } from "../../components/internal-route";
import { CandidateLogInRoute } from "../../routes/candidate/log-in";
import { MonitorSignOutRoute } from "../../routes/monitor/sign-out";
import { DTRoute } from "../../routes/dt";
import { CleaningRoute } from "../../routes/cleaning";
import { TYRoute } from "../../routes/ty";
import { MonitorScanQRRoute } from "../../routes/monitor/scan-qr";
import { TestCsrfRoute } from "../../routes/test/csrf";

export const Quantisal = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={URL_MONITOR_SIGN_OUT} element={<MonitorSignOutRoute />} />
        <Route path={URL_MONITOR_SETTINGS + "/*"} element={<InternalRoute />}>
          <Route path="*" element={<Settings />} />
        </Route>
        <Route path={URL_MONITOR_SIGN_IN + "/*"} element={<InternalRoute />}>
          <Route path="*" element={<MonitorSignInRoute />} />
        </Route>

        <Route
          path={URL_MONITOR_SCAN_QR_CODE + "/*"}
          element={<InternalRoute />}
        >
          <Route path="*" element={<MonitorScanQRRoute />} />
        </Route>

        <Route path={URL_CANDIDATE_LOG_IN + "/*"} element={<InternalRoute />}>
          <Route path="*" element={<CandidateLogInRoute />} />
        </Route>
        <Route path={URL_DT + "/*"} element={<InternalRoute />}>
          <Route path="*" element={<DTRoute />} />
        </Route>
        <Route path={URL_CLEANING + "/*"} element={<InternalRoute />}>
          <Route path="*" element={<CleaningRoute />} />
        </Route>
        <Route path={URL_TY + "/*"} element={<InternalRoute />}>
          <Route path="*" element={<TYRoute />} />
        </Route>
        {/* This test route will be removed on build by webpack */}
        {process.env.NODE_ENV === "development" && (
          <Route path={URL_TEST_CSRF} element={<TestCsrfRoute />} />
        )}
        <Route path="*" element={<Navigate to={URL_MONITOR_SIGN_IN} />} />
      </Routes>
    </BrowserRouter>
  );
};

