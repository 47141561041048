import React from "react";

import { H2, Text } from "../../../components/typography";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";

interface ConfirmDialogProps {
  title: string;
  text: string;
  imageHref?: string;
  cancelText: string;
  closeText?: string;
  confirmText: string;
  isOpen: boolean;
  onClose: () => void;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmDialog = ({
  title,
  text,
  imageHref,
  cancelText,
  confirmText,
  isOpen,
  onClose,
  onCancel,
  onConfirm,
}: ConfirmDialogProps) => {
  return (
    <Modal isOpen={isOpen} close={onClose}>
      <Col gridGap="2rem">
        <H2>{title}</H2>
        <Row>
          <Text>{text}</Text>
        </Row>
        <img className="card-image" src={imageHref} alt={imageHref} />
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onCancel}>{cancelText}</Button>
          <Button onClick={onConfirm} variant={ButtonVariant.Primary}>
            {confirmText}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
