import React, { useCallback, useContext, useEffect, useState } from "react";
import { H1 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { URL_DT_GUIDE_STICK, URL_DT_GUIDE_START } from "../../../config/urls";
import { Card } from "../../../components/card";
import { Context } from "../../../store/store";


import "./styles.scss";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";

export const DTGuideLabelRoute = () => {
  const { state } = useContext(Context);
  const [goToGuideStick, goBack] = useNavigator(
    URL_DT_GUIDE_STICK,
    URL_DT_GUIDE_START
  );
  const { t } = useTranslation();
  const { addPageLoadMetric, addGoBackEventMetric } = useAdobeAnalytics();

  const currentLabel = state.currentLabel || "A";
  const { appConfigurationState } = useContext(Context);
    
  const [image2A, setImage2A] = useState<any>(null);
  const [image3A, setImage3A] = useState<any>(null);
  const [image4A, setImage4A] = useState<any>(null);
  const [image2B, setImage2B] = useState<any>(null);
  const [image3B, setImage3B] = useState<any>(null);
  const [image4B, setImage4B] = useState<any>(null);

  const [imageText, setImageText] = useState<any>(null);

  
  useEffect(() => {
    const getDynamicImports = async () => {
        setImage2A(await import(appConfigurationState.guideLabel?.images.A.step2 + ""));
        setImage3A(await import(appConfigurationState.guideLabel?.images.A.step3 + ""));
        setImage4A(await import(appConfigurationState.guideLabel?.images.A.step4 + ""));
        setImage2B(await import(appConfigurationState.guideLabel?.images.B.step2 + ""));
        setImage3B(await import(appConfigurationState.guideLabel?.images.B.step3 + ""));
        setImage4B(await import(appConfigurationState.guideLabel?.images.B.step4 + ""));
        setImageText(appConfigurationState.guideLabel?.guideLabelText.text);
    };
    getDynamicImports();
  }, []);

  const images = {
    A: {
      step2: image2A,
      step3: image3A,
      step4: image4A,
    },
    B: {
      step2: image2B,
      step3: image3B,
      step4: image4B,
    },
  };

  useEffect(() => {
    addPageLoadMetric("drug test guide label");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addGoBackEventMetric]);

  const next = useCallback(() => {
    goToGuideStick();
  }, [goToGuideStick]);

    return (
      <Col margin="3rem 0 0 0" gridGap="2rem">
        <H1>{t("dt-guide1-title")}</H1>
        <div className="guide-form-label">
          <div className="step2">
            <Row justifyContent="center">
              <Card
                title={t("dt-guide1-step2Title")}
                text={t("dt-guide1-step2", { currentLabel })}
                imageHref={images[currentLabel].step2?.default}
              />
            </Row>
          </div>
          <div className="step3">
            <Card
              title={t("dt-guide2-step3Title")}
              text={t("dt-guide2-step3", { currentLabel })}
              imageText={t(imageText)} 
              imageTextStyle={{ top: "30px", left: "-87px" }}
              imageHref={images[currentLabel].step3?.default}
            />
          </div>
          <div className="step4">
            <Card
              title={t("dt-guide2-step4Title")}
              text={t("dt-guide2-step4")}
              imageHref={images[currentLabel].step4?.default}
            />
          </div>
        </div>
        <Row justifyContent="flex-end" gridGap="1rem" margin="3rem 0 0 0">
          <Button onClick={back}>{t("common-back")}</Button>
          <Button onClick={next} variant={ButtonVariant.Primary}>
            {t("common-next")}
          </Button>
        </Row>
      </Col>
    );
  
};
