import { useState, useEffect } from "react";

export const useNetworkStatus = (timer = 1000) => {
  const [online, setOnline] = useState(window.navigator.onLine);

  useEffect(() => {
    const interval = setInterval(
      () => setOnline(window.navigator.onLine),
      timer
    );
    return () => {
      clearInterval(interval);
    };
  }, []);

  return { online };
};
