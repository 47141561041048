import React, { useCallback, useEffect, useState } from "react";
import defaultTheme from "@amzn/stencil-react-theme-default";
import { StencilProvider } from "@amzn/stencil-react-components/context";
import { ErrorBoundary } from "./components/error-boundary";
import { SWRConfig } from "swr";
import { fetcher } from "./helpers/fetcher";

import Store from "./store/store";
import { Text } from "./components/typography";
import { useTranslation } from "react-i18next";
import { requestCsrf } from "./helpers/request-csrf";
import { enableErrorTracking } from "./error-tracker";
import { Modal } from "./components/modal";
import { Button } from "@amzn/stencil-react-components/button";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useNetworkStatus } from "./hooks/use-network-status";
import { WorkflowRouteFactory } from "./utility/workflow-route-factory";


declare global {
  export interface Window {
    dataLayerArray?: any[];
    ASH_ECOC_csrfPromise?: Promise<any>;
    BarcodeDetector: any;
  }
}

enableErrorTracking();

// TODO: Uncomment when CSRF is implemented on the backend
// requestCsrf();
console.warn("requestCsrf", "is not implemented", !!requestCsrf);

function App() {
  const { t } = useTranslation();
  const { online } = useNetworkStatus();
  const [isOfflineModalOpen, setIsOfflineModalOpen] = useState(false);
  const openOfflineModal = useCallback(() => {
    setIsOfflineModalOpen(true);
  }, []);

  const closeOfflineModal = useCallback(() => {
    setIsOfflineModalOpen(false);
  }, []);

  useEffect(() => {
    if (!online) {
      openOfflineModal();
    }
  }, [online]);

  return (
    <StencilProvider theme={defaultTheme}>
      <ErrorBoundary
        errorMessage={
          <Text textAlign="center">{t("errorBoundary-errorMessage")}</Text>
        }
      >
        <div className="app">
          <main>
            <SWRConfig
              value={{
                fetcher,
              }}
            >
              <Store>
                <WorkflowRouteFactory />
              </Store>
            </SWRConfig>
          </main>
        </div>
      </ErrorBoundary>
      <Modal isOpen={isOfflineModalOpen} close={closeOfflineModal}>
        <Col gridGap="2rem">
          <Row>
            <Text>{t("dialog-offline-message")}</Text>
          </Row>
          <Row justifyContent="flex-end" gridGap="1rem">
            <Button onClick={closeOfflineModal}>
              {t("dialog-offline-ok")}
            </Button>
          </Row>
        </Col>
      </Modal>
    </StencilProvider>
  );
}

export default App;
