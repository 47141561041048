import React, { useEffect, useRef } from "react";
import SignaturePad from "signature_pad";
import "./styles.scss";

interface SignatureProps {
  signatureRef: React.MutableRefObject<SignaturePad | undefined>;
  onDraw?: () => void;
}

export const Signature = ({ signatureRef, onDraw }: SignatureProps) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const width = window.innerWidth * 0.8;
  const height = width / 2;

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    signatureRef.current = new SignaturePad(canvasRef.current, {
      onBegin: () => {
        onDraw?.();
      },
    });
  }, []);

  return (
    <canvas
      width={width}
      height={height}
      className="signature-canvas"
      data-testid="coc-signature-canvas"
      ref={canvasRef}
    ></canvas>
  );
};
