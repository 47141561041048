import { useContext, useEffect } from "react";
import { Context } from "../../store/store";
import { ActionType } from "../../store/state";
import { URL_MONITOR_SIGN_IN } from "../../config/urls";
import { useNavigator } from "../../hooks/use-navigator";
import { RECRUITER_TOKEN_KEY } from "../../config/constants";
import Cookies from "js-cookie";

export const MonitorSignOutRoute = () => {
  const { dispatch } = useContext(Context);
  const [goToMonitorSignIn] = useNavigator(URL_MONITOR_SIGN_IN);

  useEffect(() => {
    dispatch({
      type: ActionType.MonitorSignOut,
    });

    Cookies.remove(RECRUITER_TOKEN_KEY);

    goToMonitorSignIn();
  }, []);

  return null;
};
