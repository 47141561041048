import React, { useCallback } from "react";
import { URL_MONITOR_SIGN_OUT } from "../../../config/urls";
import { H2, Text } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";

interface ExpireTokenMonitorDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ExpireTokenMonitorDialog = ({
  isOpen,
  onClose,
}: ExpireTokenMonitorDialogProps) => {
  const [goToSignOutMonitor] = useNavigator(URL_MONITOR_SIGN_OUT);
  const { t } = useTranslation();

  const signOutMonitor = useCallback(() => {
    goToSignOutMonitor();
  }, []);

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <Col gridGap="2rem">
        <H2>{t("dialog-expireTokenMonitor-title")}</H2>
        <Row>
          <Text>{t("dialog-expireTokenMonitor-message")}</Text>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onClose}>{t("common-cancel")}</Button>
          <Button onClick={signOutMonitor} variant={ButtonVariant.Primary}>
            {t("dialog-expireTokenMonitor-confirm")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
