import React, { useCallback, useContext, useEffect, useState } from "react";
import { H1, Text } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  URL_DT_BARCODE_SCAN_CAMERA,
  URL_DT_BARCODE_SCAN_LASER,
  URL_DT_FORM,
} from "../../../config/urls";
import { ActionType } from "../../../store/state";
import { Context } from "../../../store/store";
import { LoadingButton } from "../../../components/loading-button";
import { useErrorBanner } from "../../../hooks/use-error-banner";
import { collectSpecimen } from "../../../service/collect-specimen";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { load } from "../../../utility/local-storage";

export const DTBarcodeResultRoute = () => {
  const [goToDTForm, goToCameraScan, goToLaserScan] = useNavigator(
    URL_DT_FORM,
    URL_DT_BARCODE_SCAN_CAMERA,
    URL_DT_BARCODE_SCAN_LASER
  );
  const { state, dispatch } = useContext(Context);
  const [loading, setLoading] = useState(false);
  const { errorBanner, setErrorMessage } = useErrorBanner();
  const { t } = useTranslation();
  const { addPageLoadMetric, addGoBackEventMetric } = useAdobeAnalytics();

  const testTubeImage = state.specimen?.testTubeImage;
  const scannerMode = load("scannerMode") || "laser";

  useEffect(() => {
    addPageLoadMetric("barcode result");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    if (scannerMode === "laser") {
      goToLaserScan();
    } else {
      goToCameraScan();
    }
  }, [scannerMode, goToCameraScan, goToLaserScan, addGoBackEventMetric]);

  const next = useCallback(async () => {
    setLoading(true);

    try {
      if (!state.candidate) {
        throw new Error(t("exception-no_candidate_data"));
      }

      if (!state.specimen) {
        throw new Error(t("exception-no_specimen_data"));
      }

      await collectSpecimen(
        state.candidate.candidateId,
        state.candidate.accessToken,
        state.candidate.drugTestRequestId,
        {
          specimenId: state.specimen.id,
          labelUsed: state.currentLabel || "A",
          testTubeImage: testTubeImage,
        }
      );
      goToDTForm();
    } catch (e) {
      setLoading(false);
      const message = e.toTranslatedString?.(t) || e.message;
      setErrorMessage(message);
      if (e.code === 410) {
        console.warn("requestId expired!");
        dispatch({
          type: ActionType.ShowExpiredRequestDialog,
          payload: true,
        });
      }
    }
  }, []);

    return (
      <Col margin="3rem 0 0 0" gridGap="2rem">
        <H1 fontSize="T500">{t("dt-barcode-result-title")}</H1>
        {errorBanner}
        <Row justifyContent="center">
          <Text fontSize="T600">{state.specimen?.id}</Text>
        </Row>
        {testTubeImage && (
          <Row justifyContent="center">
            <img
              className="test-tube-image"
              alt="Specimen test tube"
              src={testTubeImage}
            />
          </Row>
        )}
        <Row
          justifyContent="flex-end"
          gridGap="1rem"
          margin="3rem 0 0 0"
          alignItems="center"
        >
          <Button onClick={back}>{t("common-back")}</Button>
          <LoadingButton onClick={next} variant={ButtonVariant.Primary} loading={loading}>
            {t("common-confirm")}
          </LoadingButton>
        </Row>
      </Col>
    );
  
};
