import { URL_API_START_DT } from "../config/urls";
import { fetcher } from "../helpers/fetcher";

export interface StartDrugTestPayload {
  email: string;
  stateOfTesting: string;
  testType?: string;
}

export interface StartDrugTestResponse {
  eligible: boolean;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  drugTestRequestId: string;
  dateOfBirth: string;
}

export const startDrugTest = async (
  payload: StartDrugTestPayload,
  candidateId: string,
  accessToken: string
): Promise<StartDrugTestResponse> => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("startDrugTest", "mocked request", payload);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            eligible: true,
            phoneNumber: "6157775555",
            firstName: "Jesse",
            lastName: "Pinkman",
            drugTestRequestId: "25550e60-9940-11eb-b045-83e27f19dcc4",
            dateOfBirth: "12/21/1980",
          });
          // reject(new ServerError("", 401));
        }, 1000);
      });
    }
  }

  const url = URL_API_START_DT.replace("{candidateId}", candidateId);
  return fetcher(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
