import React, { useCallback } from "react";
import { URL_DT_EXIT } from "../../../config/urls";
import { H2, Text } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";

interface CandidateWrongNameDialogProps {
  isOpen: boolean;
  onClose: () => void;
}

export const CandidateWrongNameDialog = ({
  isOpen,
  onClose,
}: CandidateWrongNameDialogProps) => {
  const [goToExitDrugTest] = useNavigator(URL_DT_EXIT);
  const { t } = useTranslation();
  const { addCustomEventMetric } = useAdobeAnalytics();

  const exitDrugTest = useCallback(() => {
    addCustomEventMetric({ eventName: "exit drug test wrong name" });
    goToExitDrugTest();
  }, []);

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <Col gridGap="2rem">
        <H2>{t("dialog-candidateWrongName-title")}</H2>
        <Row>
          <Text>{t("dialog-candidateWrongName-message")}</Text>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onClose}>{t("common-cancel")}</Button>
          <Button onClick={exitDrugTest} variant={ButtonVariant.Primary}>
            {t("dialog-candidateWrongName-confirm")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
