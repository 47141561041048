import React from "react";
import {
  Modal as StencilModal,
  ModalContent,
} from "@amzn/stencil-react-components/modal";
import { Col } from "@amzn/stencil-react-components/layout";

interface ModalProps {
  isOpen: boolean;
  close: () => void;
  children: React.ReactNode;
}

export const Modal = ({ isOpen, close, children }: ModalProps) => {
  return (
    <StencilModal isOpen={isOpen} close={close}>
      <ModalContent titleText="" maxWidth="90vw">
        <Col padding="1rem">{children}</Col>
      </ModalContent>
    </StencilModal>
  );
};
