import React, { useContext } from "react";
import { Outlet, useLocation, Navigate } from "react-router-dom";
import { URL_MONITOR_SIGN_IN } from "../../config/urls";
import { Context } from "../../store/store";

export const InternalRoute = ({ children, ...rest }: any) => {
  const { pathname } = useLocation();
  const { state } = useContext(Context);

  let url = URL_MONITOR_SIGN_IN;

  if (state.currentUrl) {
    url = state.currentUrl;
  }

  children;
  rest;

  return (
    <>{pathname === url ? <Outlet /> : <Navigate to={{ pathname: url }} />};</>
  );
};
