import React from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H2, Text } from "../typography";
import "./styles.scss";
import { secondaryTextColor } from "../../config/palette";

export enum CardTypes {
  Correct = "correct",
  Wrong = "wrong",
}

type CardProps = {
  imageHref?: string;
  type?: CardTypes;
  title?: string;
  text?: string;
  imageText?: string;
  imageTextStyle?: React.CSSProperties;
};

export const Card = ({
  imageHref,
  type = CardTypes.Correct,
  title,
  text,
  imageText,
  imageTextStyle,
}: CardProps) => {
  const className = `card ${type}`;

  return (
    <Col className={className} gridGap="1rem" justifyContent="space-between">
      {title && (
        <H2 textAlign="center" className="card-title">
          {title}
        </H2>
      )}
      <Col padding="1rem" gridGap="2rem" className="text-on-image">
        {imageHref && (
          <Row justifyContent="center" className="text-on-image">
            <img className="card-image" src={imageHref} alt={imageHref} />
            <h6 className="image-text" style={imageTextStyle}>
              {imageText}
            </h6>
          </Row>
        )}
        {text && (
          <Text fontSize="T500" color={secondaryTextColor}>
            {text}
          </Text>
        )}
      </Col>
    </Col>
  );
};
