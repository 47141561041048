import React, { useState, useCallback, useEffect } from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { H1, Label, Link } from "../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { defaultTextColor } from "../../config/palette";
import { Header } from "../../components/header";
import { useNavigator } from "../../hooks/use-navigator";
import { URL_MONITOR_SIGN_IN } from "../../config/urls";
import { Radio, Select} from "@amzn/stencil-react-components/form";
import { Button } from "@amzn/stencil-react-components/button";
import { useTranslation } from "react-i18next";
import { save, load } from "../../utility/local-storage";
import "./styles.scss";


export const Settings = () => {
  const [scannerMode, setScannerMode] = useState("laser");
  const [goToMonitor] = useNavigator(URL_MONITOR_SIGN_IN);
  const { t,i18n } = useTranslation();

  const isCamChecked = scannerMode === "camera";

  useEffect(() => {
    const existingMode = load("scannerMode");
    if (existingMode) {
      setScannerMode(existingMode);
    }
  }, []);

  const onScannerModeChange = useCallback(
    (mode) => {
      setScannerMode(mode);
    },
    [setScannerMode]
  );
  const onLanguageChange = async (e: string) => {
    const lang = [
      {
        lang: "English",
        code: "en",
      },
      {
        lang: "Spanish",
        code: "es",
      },
    ];

    let selectedLangauge = "en";
    lang.map((l) => {
      if (l.lang === e) {
        selectedLangauge = l.code;
      }
    });

    console.log(selectedLangauge);
    i18n.changeLanguage(selectedLangauge);
  };

  const onSave = useCallback(() => {
    save("scannerMode", scannerMode);
    goToMonitor();
  }, [goToMonitor, scannerMode]);

  return (
    <PageContainer color={defaultTextColor}>
      <Header>
        <span className="no-print exit-drug-test__link" onClick={goToMonitor}>
          <Link>{t("common-goBack")}</Link>
        </span>
      </Header>
      <Col gridGap="4rem" margin="5rem 0 0 0" alignItems="center">
        <H1 fontSize="T600">{t("settings-title")}</H1>
        <div className="sign-in-form">
          <div className="scannerModeLabel">
            <Label htmlFor="scanner-mode">{t("settings-scannerMode")}</Label>
          </div>
          <div className="scannerMode">
            <Col gridGap="S200">
              <Row alignItems="center" gridGap="S200">
                <Radio
                  checked={!isCamChecked}
                  id="scanner-mode-laser"
                  name="scannerMode"
                  value="laser"
                  onChange={() => onScannerModeChange("laser")}
                />
                <Label htmlFor="scanner-mode-laser">
                  {t("settings-barcode")}
                </Label>
              </Row>
              <Row alignItems="center" gridGap="S200">
                <Radio
                  id="scanner-mode-camera"
                  checked={isCamChecked}
                  name="scannerMode"
                  value="camera"
                  onChange={() => onScannerModeChange("camera")}
                />
                <Label htmlFor="scanner-mode-camera">
                  {t("settings-camera")}
                </Label>

              </Row>
            </Col>
            
          
            
          </div>
          <div className="languageLabel">
              <Label htmlFor="language" id="language-label">
                {t("candidate-logIn-language")}
              </Label>
            </div>
            <div className="language">
              <Select
                labelId="language-label"
                id="language"
                options={["English", "Spanish"]}
                onChange={onLanguageChange}
                placeholder={t("candidate-logIn-language")}
              />
            </div>
     
        </div>
        <Button onClick={onSave}>
          {t("common-save")}
        </Button>
      </Col>
    </PageContainer>
  );
};
