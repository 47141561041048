import React, { useCallback, useContext, useEffect, useState } from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H1, H2, Text, Link, Label } from "../../components/typography";
import { defaultTextColor } from "../../config/palette";
import { Header } from "../../components/header";
import { useTranslation } from "react-i18next";
import { useNavigator } from "../../hooks/use-navigator";
import {
  URL_DT_CANDIDATE_CONFIRM,
  URL_MONITOR_SIGN_OUT,
} from "../../config/urls";
import {
  Input,
  InputFooter,
  Select,
} from "@amzn/stencil-react-components/form";
import { Modal } from "../../components/modal";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import "./styles.scss";
import { Context } from "../../store/store";
import { ActionType, Candidate } from "../../store/state";
import { LoadingButton } from "../../components/loading-button";
import { logIn } from "../../service/log-in";
import { startDrugTest } from "../../service/start-drug-test";
import { validateEmail, validatePhoneTail } from "../../utility/validation";
import { useErrorBanner } from "../../hooks/use-error-banner";
import { getInitialData } from "./initial-data";
import { isTokenExpiringSoon } from "../../utility/recruiter-token";
import { ExpireTokenMonitorDialog } from "../dt/dialogs/expire-token-monitor";
import { useAdobeAnalytics } from "../../hooks/use-adobe-analytics";
import { IconInformationFill } from "@amzn/stencil-react-components/icons";
import { Status, StatusIndicator } from "@amzn/stencil-react-components/status-indicator";
import { useMetrics } from "../../hooks/use-metrics";
import { isIntegrationTestMode } from "../../utility/local-storage";

const initialData = getInitialData();

const { sendMetric } = useMetrics();
export const CandidateLogInRoute = () => {
  const { state, dispatch } = useContext(Context);
  const [goToMonitorSignOut] = useNavigator(URL_MONITOR_SIGN_OUT);
  const [goToCandidateConfirm] = useNavigator(URL_DT_CANDIDATE_CONFIRM);
  const { addPageLoadMetric } = useAdobeAnalytics();
  const [
    isExpireTokenMonitorDialogOpen,
    setIsExpireTokenMonitorDialogOpen,
  ] = useState(false);

  const { errorBanner, setErrorMessage } = useErrorBanner();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(initialData.email);
  const [phoneTail, setphoneTail] = useState(initialData.phoneTail);
  const [isLogOutModalOpen, setIsLogOutModalOpen] = useState(false);
  const [phoneTailValid, setphoneTailValid] = useState(true);
  const [emailValid, setEmailValid] = useState(true);
  const { t, i18n } = useTranslation();
  const { appConfigurationState } = useContext(Context);

  console.log(appConfigurationState.workflowId);

  const openExpireTokenMonitorDialog = useCallback(() => {
    setIsExpireTokenMonitorDialogOpen(true);
  }, []);

  const closeExpireTokenMonitorDialog = useCallback(() => {
    setIsExpireTokenMonitorDialogOpen(false);
  }, []);

  const checkMonitorToken = useCallback(() => {
    try {
      if (isTokenExpiringSoon()) {
        openExpireTokenMonitorDialog();
        return false;
      }
    } catch (e) {
      goToMonitorSignOut();
    }
    return true;
  }, []);

  useEffect(() => {
    addPageLoadMetric("candidate log in");
    checkMonitorToken();

    // There was a bug in stencil with playwright that cause the overlapping for the select component which cause the element overlapping each other.
    // Added custom css for offsetting the element
    if (isIntegrationTestMode()) {
      const styles = `
        .css-lf65sd {
          margin-top: 5px !important;
        }
      `;

      const styleSheet = document.createElement("style");
      styleSheet.innerText = styles;
      document.head.appendChild(styleSheet);
    }
  }, []);

  const logOut = useCallback(() => {
    goToMonitorSignOut();
  }, []);

  const onEmailChange = useCallback((e) => {
    const email = e.target.value;
    const isEmailValid = validateEmail(email);
    setEmail(email);
    setEmailValid(isEmailValid);
  }, []);

  const onphoneTailChange = useCallback((e) => {
    const phoneTail = e.target.value;
    const isphoneTailValid = validatePhoneTail(phoneTail);
    setphoneTail(phoneTail);
    setphoneTailValid(isphoneTailValid);
  }, []);

  const openLogOutModal = useCallback(() => {
    !loading && setIsLogOutModalOpen(true);
  }, [loading]);

  const closeLogOutModal = useCallback(() => {
    setIsLogOutModalOpen(false);
  }, []);

  const createDrugTest = useCallback(async () => {
    if (!checkMonitorToken()) {
      return;
    }

    if (!state.monitor) {
      throw new Error(t("exception-no_monitor_data"));
    }

    const isEmailValid = validateEmail(email);
    const isphoneTailValid = validatePhoneTail(phoneTail);

    if (!isEmailValid || !isphoneTailValid) {
      setEmailValid(isEmailValid);
      setphoneTailValid(isphoneTailValid);
      return false;
    }

    setLoading(true);

    try {
      const logInResponse = await logIn({
        email,
        phoneTail,
      });

      if (!logInResponse) {
        throw new Error(t("exception-no_candidate_data"));
      } else {
        sendMetric({ event: "candidateUi.login.sucess" });
      }
      const startDrugTestResponse = await startDrugTest(
        {
          email,
          stateOfTesting: state.monitor.stateOfTesting,
          testType: appConfigurationState.workflowId,
        },
        logInResponse.candidateId,
        logInResponse.accessToken
      );

      if (!startDrugTestResponse.eligible) {
        throw new Error(t("candidate-logIn-notEligible"));
      }

      dispatch({
        type: ActionType.SetCandidate,
        payload: {
          ...startDrugTestResponse,
          ...logInResponse,
          stateOfTesting: state.monitor.stateOfTesting,
        } as Candidate,
      });
      goToCandidateConfirm();
    } catch (e) {
      setLoading(false);
      const message = e.toTranslatedString?.(t) || e.message;
      setErrorMessage(message);
    }
  }, [email, phoneTail]);

  const onLanguageChange = async (e: string) => {
    const lang = [
      {
        lang: "English",
        code: "en",
      },
      {
        lang: "Spanish",
        code: "es",
      },
    ];

    let selectedLangauge = "en";
    lang.map((l) => {
      if (l.lang === e) {
        selectedLangauge = l.code;
      }
    });

    console.log(selectedLangauge);
    i18n.changeLanguage(selectedLangauge);
  };

  return (
    <>
      <PageContainer color={defaultTextColor}>
        <Header>
          <span onClick={openLogOutModal}>
            <Link>{t("dialog-monitorSignOut-linkText")}</Link>
          </span>
        </Header>
        <Col gridGap="4rem" margin="5rem 0 0 0" alignItems="center">
          <H1>{t("candidate-logIn-title")}</H1>
          {errorBanner}

          <div className="log-in-form">
            <div className="emailLabel">
              <Label htmlFor="email">{t("candidate-logIn-email")}</Label>
            </div>
            <div className="email">
              <Input
                id="email"
                autoComplete="off"
                type="email"
                width="100%"
                error={!emailValid}
                placeholder={t("candidate-logIn-emailPlaceholder")}
                value={email}
                onChange={onEmailChange}
                readOnly={loading}
              />
            </div>

            <div className="emailValidation">
              {!emailValid && (
                <InputFooter warning={true}>
                  <IconInformationFill></IconInformationFill>
                  {t("candidate-logIn-emailValidation")}
                </InputFooter>
              )}
              <br />
            </div>
            <div className="phoneTailLabel">
              <Label htmlFor="phone-tail">
                {t("candidate-logIn-phoneTail")}
              </Label>
            </div>

            <div className="phoneTail">
              <Input
                id="phone-tail"
                autoComplete="off"
                type="number"
                width="100%"
                error={!phoneTailValid}
                placeholder={t("candidate-logIn-phoneTailPlaceholder")}
                value={phoneTail}
                onChange={onphoneTailChange}
                readOnly={loading}
              />
            </div>
            <div className="phoneTailValidation">
              {!phoneTailValid && (
                <InputFooter warning={true}>
                  {t("candidate-logIn-phoneValidation")}
                </InputFooter>
              )}
              <br />
            </div>

            <div className="languageLabel">
              <Label htmlFor="language" id="language-label">
                {t("candidate-logIn-language")}
              </Label>
            </div>
            <div className="language">
              <Select
                labelId="language-label"
                id="language"
                options={["English", "Spanish"]}
                onChange={onLanguageChange}
                placeholder={t("candidate-logIn-language")}
              />
            </div>
          </div>

          <LoadingButton loading={loading} variant={ButtonVariant.Primary} onClick={createDrugTest}>
            {t("candidate-logIn-buttonLogIn")}
          </LoadingButton>
          <div className="phoneInfo">
            <StatusIndicator
              status={Status.Information}
              messageText={t(
                "dialog-infoText-title"
                // "Please use the contact information you applied with"
              )}
            />
          </div>
        </Col>

        <Modal isOpen={isLogOutModalOpen} close={closeLogOutModal}>
          <Col gridGap="2rem">
            <H2>{t("dialog-monitorSignOut-title")}</H2>
            <Row>
              <Text>{t("dialog-monitorSignOut-message")}</Text>
            </Row>
            <Row justifyContent="flex-end" gridGap="1rem">
              <Button onClick={closeLogOutModal}>
                {t("dialog-monitorSignOut-cancel")}
              </Button>
              <Button onClick={logOut} variant={ButtonVariant.Primary}>
                {t("dialog-monitorSignOut-confirm")}
              </Button>
            </Row>
          </Col>
        </Modal>

        <ExpireTokenMonitorDialog
          isOpen={isExpireTokenMonitorDialogOpen}
          onClose={closeExpireTokenMonitorDialog}
        ></ExpireTokenMonitorDialog>
      </PageContainer>
    </>
  );
};
