/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from "react";
import { URL_DT_EXIT } from "../../../config/urls";
import { H2, Text } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";

dayjs.extend(duration);
const TIMEOUT = 3 * 60 * 1000;

interface ExpireSessionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  timeout?: number;
}

export const ExpireSessionDialog = ({
  isOpen,
  onClose,
  timeout = TIMEOUT,
}: ExpireSessionDialogProps) => {
  const [duration, setDuration] = useState<string>();
  const timeLeftRef = useRef(timeout);
  const intervalRef = useRef<NodeJS.Timeout>();
  const [goToExitDrugTest] = useNavigator(URL_DT_EXIT);
  const { t } = useTranslation();
  const { addCustomEventMetric } = useAdobeAnalytics();

  useEffect(() => {
    if (isOpen) {
      timeLeftRef.current = timeout;
      setDuration(dayjs.duration(timeLeftRef.current).format("mm:ss"));
      intervalRef.current = setInterval(() => {
        timeLeftRef.current -= 1000;
        if (timeLeftRef.current <= 0) {
          addCustomEventMetric({ eventName: "exit drug test idle" });
          goToExitDrugTest();
        } else {
          setDuration(dayjs.duration(timeLeftRef.current).format("mm:ss"));
        }
      }, 1000);
    }

    if (!isOpen && intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <Col gridGap="2rem">
        <H2>{t("dialog-expireSession-title")}</H2>
        <Row>
          <Text>
            {t("dialog-expireSession-message", { timeLeft: duration })}
          </Text>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onClose} variant={ButtonVariant.Primary}>
            {t("dialog-expireSession-confirm")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
