import { URL_API_CANDIDATE_LOG_IN } from "../config/urls";
import { fetcher } from "../helpers/fetcher";

export interface LogInPayload {
  email: string;
  phoneTail: string;
}

export interface LogInResponse {
  accessToken: string;
  candidateId: string;
}

export const logIn = async (payload: LogInPayload): Promise<LogInResponse> => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("logIn", "mocked request", payload);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            accessToken:
              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6Ijg5OTg5NDYxLTU1OTYtNDNkYS04MmU4LTdmODcwZTMyNGE1MyJ9.eyJjYW5kaWRhdGVJZCI6IkNJRDAzNTY4NjcxNSIsImlhdCI6MTYxODI2MTI2OCwiZXhwIjoxNjE4MzA0NDY4fQ.yevgt_j66PkydrKoehkSQcXNAY1-MnvGi7C9d6f3h20",
            candidateId: "CID035686715",
          });
          // reject(new ServerError("", 403));
        }, 1000);
      });
    }
  }

  return fetcher(URL_API_CANDIDATE_LOG_IN, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
    },
  });
};
