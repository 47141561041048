import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "../../../components/typography";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  URL_DT_BARCODE_SCAN_CAMERA,
  URL_DT_GUIDE_STICK,
} from "../../../config/urls";
import { useTranslation } from "react-i18next";
import { Button } from "@amzn/stencil-react-components/button";

import "./styles.scss";

import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { Context } from "../../../store/store";

export const DTBarcodeScanCameraInstructionsRoute = () => {
  const [gotToBarcodeScanWithCam, goBack] = useNavigator(
    URL_DT_BARCODE_SCAN_CAMERA,
    URL_DT_GUIDE_STICK
  );
  const { addPageLoadMetric } = useAdobeAnalytics();
  const { t } = useTranslation();
  const { appConfigurationState } = useContext(Context);

  const [image1, setImage1] = useState(null);
  const [image2, setImage2] = useState(null);
  const [image3, setImage3] = useState(null);
  const [image4, setImage4] = useState(null);

  useEffect(() => {
    const getDynamicImports = async () => {
      setImage1(
        await import(appConfigurationState.scanCameraInstructions.image1 + "")
      );
      setImage2(
        await import(appConfigurationState.scanCameraInstructions.image2 + "")
      );
      setImage3(
        await import(appConfigurationState.scanCameraInstructions.image3 + "")
      );
      setImage4(
        await import(appConfigurationState.scanCameraInstructions.image4 + "")
      );
    };
    getDynamicImports();
  }, []);

  useEffect(() => {
    addPageLoadMetric("barcode scan laser");
  }, []);

  const back = useCallback(() => {
    goBack();
  }, [goBack]);

  const next = useCallback(() => {
    console.log(
      "**********************************************************************"
    );
    console.log(
      "**********************************************************************"
    );
    gotToBarcodeScanWithCam();
  }, [gotToBarcodeScanWithCam]);

  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      <H1 fontSize="T600">{t("dt-camera-title")}</H1>
      <Text>{t("dt-camera-guidance1")}</Text>
      <img
        alt="Point the scanner machine to scan the barcode"
        data-testid="dt-camera-guidance1"
        src={image1?.default}
        className="laser-scan-image"
      />

      <Text>{t("dt-camera-guidance2")}</Text>
      <img
        alt="Scan the barcode by pressing the button on the scanner machine"
        data-testid="dt-camera-guidance2"
        src={image2?.default}
        className="laser-scan-image"
      />

      <Text>{t("dt-camera-guidance3")}</Text>
      <img
        alt="Scan the barcode by pressing the button on the scanner machine"
        data-testid="dt-camera-guidance3"
        src={image3?.default}
        className="laser-scan-image"
      />

      <Text>{t("dt-camera-guidance4")}</Text>
      <img
        alt="Scan the barcode by pressing the button on the scanner machine"
        data-testid="dt-camera-guidance4"
        src={image4?.default}
        className="laser-scan-image"
      />

      <Row justifyContent="flex-end" gridGap="1rem">
        <Button onClick={back}>{t("common-back")}</Button>
        <Button onClick={next}>{t("open-camera")}</Button>
      </Row>
    </Col>
  );
};
