import { URL_API_DT_COLLECT_SPECIMEN } from "../config/urls";
import { fetcher } from "../helpers/fetcher";
import { SpecimenLabel } from "../store/state";

export interface CollectSpecimenPayload {
  specimenId: string;
  labelUsed: SpecimenLabel;
  testTubeImage?: string;
}

export const collectSpecimen = async (
  candidateId: string,
  accessToken: string,
  drugTestRequestId: string,
  payload: CollectSpecimenPayload
) => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("collectSpecimen", "mocked request", payload);
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
          // reject(new ServerError("", 409));
        }, 1000);
      });
    }
  }

  const url = URL_API_DT_COLLECT_SPECIMEN.replace(
    "{candidateId}",
    candidateId
  ).replace("{drugTestRequestId}", drugTestRequestId);

  return fetcher(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
