import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row } from "@amzn/stencil-react-components/layout";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import { Text } from "../typography";
import { defaultTextColor } from "../../config/palette";
import "./styles.scss";

dayjs.extend(duration);

type CountdownProps = {
  timer: number;
  running?: boolean;
  fontSize?: string;
  onStarted?: () => void;
  onFinished?: () => void;
};

export const Countdown = ({
  timer,
  running,
  fontSize = "T600",
  onStarted,
  onFinished,
}: CountdownProps) => {
  const timeLeftRef = useRef(timer);
  const intervalRef = useRef<NodeJS.Timeout>();
  const [duration, setDuration] = useState(dayjs.duration(timeLeftRef.current));

  const stop = useCallback(() => {
    intervalRef.current && clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (running) {
      intervalRef.current = setInterval(() => {
        if (timeLeftRef.current <= 0) {
          return;
        }
        timeLeftRef.current -= 1000;
        if (timeLeftRef.current <= 0) {
          onFinished && onFinished();
          stop();
        }
        setDuration(dayjs.duration(timeLeftRef.current));
      }, 1000);
      onStarted && onStarted();
    } else {
      stop();
    }
    return stop;
  }, [running]);

  const timeLeftFormatted = duration.format("mm:ss");

  return (
    <Row>
      <Text
        className="countdown"
        color={defaultTextColor}
        fontSize={fontSize}
        data-testid="dt-timer"
      >
        {timeLeftFormatted}
      </Text>
    </Row>
  );
};
