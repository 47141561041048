import React, { useContext, useEffect } from "react";
import { Quantisal } from "../../../HvhSimpleHireDTCandidateUI/src/./workflow-routings/quantisal/quantisal";
import { Context } from "../store/store";

export const WorkflowRouteFactory = () => {
  const { appConfigurationState, setAppConfigurationState } = useContext(Context);
  const workflowId = appConfigurationState.workflowId;

  if (workflowId === null) {
    return <Quantisal />;
  } 
  else if (workflowId === "quantisal") {
    useEffect(() => {
        setAppConfigurationState({
          ...appConfigurationState,
          timerBypassLength: 1000 * 60 * 5,
          scanCameraInstructions: {
            image1: "./quantisalInStand.jpeg",
            image2: "./quantisalMid.jpeg",
            image3: "./quantisalStandHolder.jpeg",
            image4: "./quantisalSuccess.jpeg"
          },
          guideLabel: {
            images: {
              A: {
                step2: "./quantisalLabel1-1.png",
                step3: "./quantisalLabel1-2.png",
                step4: "./quantisalLabel1-3.png",
              },
              B: {
                step2: "./step2-b.png",
                step3: "./quantisalLabel1-2b.png",
                step4: "./quantisalLabel1-3b.png",
              },
            },
            guideLabelText:{
              text: "",
            },
          },
          guideStart: {
            images: {
              step1: "./quantisal.png",
            }
          },
          guideStick: {
            images: {
              A: {
                step5: "./quantisalSticka1.png",
                wrong1: "./quantisalSticka2.png",
                wrong2: "./quantisalSticka3.png",
              },
              B: {
                step5: "./quantisalStickb1.png",
                wrong1: "./quantisalStickb2.png",
                wrong2: "./quantisalStickb3.png",
              },
            },
          },
          signIn: {
            vendorConfirmationImage: "./quantisal.png"
          }
        });
      },
      [appConfigurationState.workflowId]  
    )

    return <Quantisal />;
  }

  return null;
};
