import React from "react";
import "./styles.scss";

export const ScanTarget = () => {
  return (
    <div className="target">
      <div className="lt"></div>
      <div className="rt"></div>
      <div className="lb"></div>
      <div className="rb"></div>
    </div>
  );
};
