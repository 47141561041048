import React, { useCallback, useEffect } from "react";
import { H1 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
// import { URL_CLEANING } from "../../../config/urls";
import finalStep1 from "./final-step1.jpeg";
import finalStep2 from "./final-step2.jpeg";
import finalStep3 from "./final-step3.jpeg";

import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { nextUrl } from "../../../hoc/with-current-workflow-progress";

export const DTGuideFinal = () => {
  const [goToCleaning] = useNavigator(nextUrl());
  const { t } = useTranslation();
  const { addPageLoadMetric } = useAdobeAnalytics();

  useEffect(() => {
    addPageLoadMetric("drug test guide final");
  }, []);

  const next = useCallback(() => {
    goToCleaning();
  }, []);

  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      <H1 fontSize="T500">{t("dt-guide4-title")}</H1>
      <img src={finalStep1} alt="finalStep1" />
      <img src={finalStep2} alt="finalStep2" />
      <img src={finalStep3} alt="finalStep3" />
      <Row justifyContent="flex-end" gridGap="1rem">
        <Button onClick={next} variant={ButtonVariant.Primary}>
          {t("common-next")}
        </Button>
      </Row>
    </Col>
  );
};
