export interface Candidate {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  candidateId: string;
  accessToken: string;
  drugTestRequestId: string;
  dateOfBirth: string;
  stateOfTesting: string;
}

export interface Monitor {
  stateOfTesting: string;
}

export type SpecimenLabel = "A" | "B";

export interface Specimen {
  id: string;
  labelUsed: SpecimenLabel;
  testTubeImage?: string;
}

export interface State {
  monitor?: Monitor;
  candidate?: Candidate;
  currentUrl: string;
  specimen?: Specimen;
  currentLabel?: SpecimenLabel;
  timerFinished?: boolean;
  recruiterToken?: string;
  expiredRequest: boolean;
}

export interface Action {
  payload?: any;
  type: ActionType;
}

export enum ActionType {
  SetSpecimen = "SET_SPECIMEN",
  SetMonitor = "SET_MONITOR",
  SetRecruiterToken = "SET_RECRUITER_TOKEN",
  MonitorSignOut = "MONITOR_SIGN_OUT",
  SetCandidate = "SET_CANDIDATE",
  UpdatePhoneNumber = "UPDATE_PHONE_NUMBER",
  UpdateDateOfBirth = "UPDATE_DATE_OF_BIRTH",
  ExitDrugTest = "EXIT_DRUG_TEST",
  SetCurrentUrl = "SET_CURRENT_URL",
  SetCurrentLabel = "SET_CURRENT_LABEL",
  ShowExpiredRequestDialog = "SHOW_EXPIRED_REQUEST_DIALOG",
  //SetWorkFlowId = "SET_WORK_FLOW_ID",
}

export interface ScanCameraInstructions {
  image1: string,
  image2: string,
  image3: string,
  image4: string,
}

export interface GuideLabel {
  images: {
    A: {
      step2: string,
      step3: string,
      step4: string,
    },
    B: {
      step2: string,
      step3: string,
      step4: string,
    },
  },guideLabelText:{
    text: string,
  },
}

export interface GuideStart {
  images: {
    step1: string,
  }
  imageTextKeys?: {
    step1?: string,
  }
}

export interface GuideStick {
  images: {
    A: {
      step5: string,
      wrong1: string,
      wrong2: string,
    },
    B: {
      step5: string,
      wrong1: string,
      wrong2: string,
    },
  },
}

export interface SignIn {
  vendorConfirmationImage: string
}

export interface AppConfiguration {
  workflowId: any,
  timerBypassLength: number | null,
  scanCameraInstructions?: ScanCameraInstructions,
  guideLabel?: GuideLabel,
  guideStart?: GuideStart,
  guideStick?: GuideStick,
  signIn?: SignIn,
}

