import React, { useCallback, useState, useEffect, useContext } from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { defaultTextColor } from "../../config/palette";
import { Header } from "../../components/header";
import { Link } from "../../components/typography";
import { useTranslation } from "react-i18next";

import { ExitDrugTestDialog } from "./dialogs/exit-drug-test";
import { ExpireSessionDialog } from "./dialogs/expire-session";
import { ExpireRequestDialog } from "./dialogs/expire-request";
import { useIdleTimer } from "react-idle-timer";

import { Col } from "@amzn/stencil-react-components/layout";
import { CurrentWorkflowProgress } from "../../components/workflow-progress";
import { Context } from "../../store/store";
import "../../index.scss";

import { WorkflowInternalRouteFactory } from "./../../utility/workflow-internal-route-factory";

const IDLE_TIMEOUT = 1000 * 60 * 12;

export const DTRoute = () => {
  const { state } = useContext(Context);
  const [loading] = useState(false);
  const [isExitDrugTestDialogOpen, setIsExitDrugTestDialogOpen] = useState(
    false
  );
  const [isExpireRequestDialogOpen, setIsExpireRequestDialogOpen] = useState(
    false
  );
  const [isExpireSessionDialogOpen, setIsExpireSessionDialogOpen] = useState(
    false
  );
  const { t } = useTranslation();

  const openExitDrugTestDialog = useCallback(() => {
    !loading && setIsExitDrugTestDialogOpen(true);
  }, [loading]);

  const closeExitDrugTestDialog = useCallback(() => {
    setIsExitDrugTestDialogOpen(false);
  }, []);

  useEffect(() => {
    if (state.expiredRequest) {
      setIsExpireRequestDialogOpen(true);
    }
  });

  const closeExpireSessionDialog = useCallback(() => {
    setIsExpireSessionDialogOpen(false);
  }, []);

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => {
      setIsExpireSessionDialogOpen(true);
    },
  });

  return (
    <>
      <PageContainer color={defaultTextColor}>
        <Col className="StickyHeader">
          <Header>
            <span
              className="no-print exit-drug-test__link"
              onClick={openExitDrugTestDialog}
            >
              <Link>{t("dialog-exitDrugTest-linkText")}</Link>
            </span>
          </Header>
          <Col margin="2rem 0 0 0">
            <CurrentWorkflowProgress />
          </Col>
        </Col>

        <WorkflowInternalRouteFactory />

        <ExitDrugTestDialog
          isOpen={isExitDrugTestDialogOpen}
          onClose={closeExitDrugTestDialog}
        ></ExitDrugTestDialog>

        <ExpireSessionDialog
          isOpen={isExpireSessionDialogOpen}
          onClose={closeExpireSessionDialog}
        ></ExpireSessionDialog>

        <ExpireRequestDialog
          isOpen={isExpireRequestDialogOpen}
        ></ExpireRequestDialog>
      </PageContainer>
    </>
  );
};
