export enum Stage {
  Beta = "beta",
  Gamma = "gamma",
  Prod = "prod",
}

export const StagePrefix: Record<Stage, string> = {
  [Stage.Beta]: "beta.pdx.",
  [Stage.Gamma]: "gamma.iad.",
  [Stage.Prod]: "",
};

export const getStage = (): Stage => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    return Stage.Beta;
  }
  if (
    location.host.startsWith(StagePrefix[Stage.Beta]) ||
    location.host.startsWith("localhost")
  ) {
    return Stage.Beta;
  } else if (location.host.startsWith(StagePrefix[Stage.Gamma])) {
    return Stage.Gamma;
  } else {
    return Stage.Prod;
  }
};
