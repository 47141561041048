import { convertImage, OutputImageFormat } from "@amzn/hvh-camera-ui";
import Quagga from "quagga";

interface BoundingBox {
  left: number;
  top: number;
  right: number;
  bottom: number;
}

interface Rect {
  x: number;
  y: number;
  width: number;
  height: number;
}

interface BarcodeResult {
  code: string;
  boundingBox: BoundingBox;
}

const getBoundingBox = (box: any): BoundingBox => {
  const xCoordinates = box.map((coord: Array<number>) => coord[0]);
  const yCoordinates = box.map((coord: Array<number>) => coord[1]);
  const left = Math.min(...xCoordinates);
  const top = Math.min(...yCoordinates);
  const right = Math.max(...xCoordinates);
  const bottom = Math.max(...yCoordinates);
  return {
    left,
    top,
    right,
    bottom,
  };
};

const getBarcodeArea = (boundingBox: BoundingBox): Rect => {
  return {
    x: boundingBox.left,
    y: boundingBox.top,
    width: boundingBox.right - boundingBox.left,
    height: boundingBox.bottom - boundingBox.top,
  };
};

export const detectBarcodeNative = async (
  imageData: string
): Promise<BarcodeResult | null> => {
  const barcodeDetector = new window.BarcodeDetector();
  const imageEl = (await convertImage(
    imageData,
    OutputImageFormat.ImageElement
  )) as HTMLImageElement;
  const barcodes = await barcodeDetector.detect(imageEl);
  if (barcodes.length > 0) {
    const barcode = barcodes[0];
    return {
      code: barcode.rawValue,
      boundingBox: barcode.boundingBox,
    };
  }
  return null;
};

export const detectBarcodeQuagga = async (
  imageData: string
): Promise<BarcodeResult | null> => {
  return new Promise((resolve) => {
    Quagga.decodeSingle(
      {
        src: imageData,
        numOfWorkers: navigator.hardwareConcurrency,
        locate: true,
        inputStream: {
          size: 1920,
        },
        locator: {
          patchSize: "medium",
          halfSample: true,
        },
        decoder: {
          readers: [
            "code_128_reader",
            "ean_reader",
            "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
            "codabar_reader",
            "upc_reader",
            "upc_e_reader",
            "i2of5_reader",
            "2of5_reader",
            "code_93_reader",
          ],
        },
      },
      async (result: any) => {
        const code = result?.codeResult?.code;
        if (!code) {
          resolve(null);
          return;
        }

        const boundingBox = getBoundingBox(result.box);
        resolve({
          code,
          boundingBox,
        });
      }
    );
  });
};

export const detectBarcodeArea = async (
  imageData: string
): Promise<Rect | null> => {
  return new Promise((resolve) => {
    Quagga.decodeSingle(
      {
        src: imageData,
        numOfWorkers: navigator.hardwareConcurrency,
        locate: true,
        inputStream: {
          size: 1920,
        },
        locator: {
          patchSize: "large",
          halfSample: true,
        },
        decoder: {
          readers: [
            "code_128_reader",
            "ean_reader",
            "ean_8_reader",
            "code_39_reader",
            "code_39_vin_reader",
            "codabar_reader",
            "upc_reader",
            "upc_e_reader",
            "i2of5_reader",
            "2of5_reader",
            "code_93_reader",
          ],
        },
      },
      async (result: any) => {
        const box = result?.box;
        if (!box) {
          resolve(null);
          return;
        }

        const boundingBox = getBoundingBox(result.box);
        resolve(getBarcodeArea(boundingBox));
      }
    );
  });
};
