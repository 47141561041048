import React, { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Modal } from "../modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Signature } from ".";
import SignaturePad from "signature_pad";
import { H3 } from "../typography";

interface SignatureDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (imageData: string) => void;
}

export const SignatureDialog = ({
  isOpen,
  onClose,
  onSubmit,
}: SignatureDialogProps) => {
  const { t } = useTranslation();
  const [isEmpty, setIsEmpty] = useState(true);

  const signatureRef = useRef<SignaturePad>();

  const clear = useCallback(() => {
    signatureRef.current?.clear();
    setIsEmpty(true);
  }, []);

  const save = useCallback(() => {
    const imageData = signatureRef.current?.toDataURL();
    if (!imageData) {
      return;
    }
    onSubmit(imageData);
    signatureRef.current?.clear();
    setIsEmpty(true);
    onClose();
  }, []);

  const onDraw = useCallback(() => {
    setIsEmpty(false);
  }, []);

  return (
    <Modal isOpen={isOpen} close={onClose}>
      <Col gridGap="2rem">
        <H3>{t("dialog-signature-title")}</H3>
        <Row justifyContent="center">
          <Signature signatureRef={signatureRef} onDraw={onDraw} />
        </Row>
        <Row gridGap="1rem" justifyContent="flex-end">
          <Button onClick={clear}>{t("dialog-signature-clear")}</Button>
          <Button variant={ButtonVariant.Primary} onClick={save} disabled={isEmpty}>
            {t("common-save")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
