import { debounce } from "lodash";
import { Deferred } from "./deferred";

const INPUT_DELAY = 50;
const MIN_SEQUENCE_LENGTH = 5;

export const inputSequenceReader = () => {
  let data = "";
  let defer: Deferred;

  const reset = debounce(() => {
    data.length > MIN_SEQUENCE_LENGTH && defer?.resolve(data);
    data = "";
  }, INPUT_DELAY);

  return (key: string) => {
    defer = new Deferred();
    reset();

    if (key === "Enter") {
      defer.resolve(data);
    }

    data += key;
    return defer;
  };
};
