import { Stage, getStage } from "../helpers/stage-info";

const PREFIX = "ASH_ECOC_";

export const save = (key: string, value: any) => {
  localStorage.setItem(PREFIX + key, JSON.stringify(value));
};

export const load = (key: string): any => {
  return JSON.parse(localStorage.getItem(PREFIX + key) as string);
};

// Flag used for integration testing
export const isIntegrationTestMode = () => {
  if (getStage() === Stage.Beta) {
    return (
      JSON.parse(
        localStorage.getItem(PREFIX + "isIntegrationTestMode") as string
      ) === true
    );
  }

  return false;
};
