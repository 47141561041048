import React, { useCallback, useContext, useEffect, useState } from "react";
import { H1 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { Card } from "../../../components/card";
import "./styles.scss";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { nextUrl, previousUrl } from "../../../hoc/with-current-workflow-progress";
import { Context } from "../../../store/store";

export const DTGuideStartRoute = () => {
  const [goToGuideLabel, goBack] = useNavigator(
    nextUrl(),
    previousUrl()
  );
  const { t } = useTranslation();
  const { addPageLoadMetric, addGoBackEventMetric } = useAdobeAnalytics();
  const { appConfigurationState } = useContext(Context);

  const [imageStep1, setImageStep1] = useState<any>(null);
  useEffect(() => {
    const getDynamicImports = async () => {
      if (appConfigurationState.guideStart?.images.step1) {
        setImageStep1(await import((appConfigurationState.guideStart?.images.step1 + "")));
      }
    }
    getDynamicImports();
  }, []);

  useEffect(() => {
    addPageLoadMetric("drug test guide start");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, []);

  const next = useCallback(() => {
    goToGuideLabel();
  }, []);

  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      <H1>{t("dt-guide1-title")}</H1>
      <Row justifyContent="center">
        <Card
          title={t("dt-guide1-step1Title")}
          text={t("dt-guide1-step1")}
          imageText={t(appConfigurationState.guideStart?.imageTextKeys?.step1 || "") || ""}
          imageHref={imageStep1?.default}
        />
      </Row>
      <Row justifyContent="flex-end" gridGap="1rem" margin="3rem 0 0 0">
        <Button onClick={back}>{t("common-back")}</Button>
        <Button onClick={next} variant={ButtonVariant.Primary}>
          {t("common-next")}
        </Button>
      </Row>
    </Col>
  );

};
