import React, { createContext, Dispatch, useEffect, useReducer, useState } from "react";
import { load, save } from "../utility/local-storage";
import Reducer from "./reducer";
import { Action, State, AppConfiguration } from "./state";

const STATE_KEY = "state";
const APP_CONF_KEY = "app_conf_state";

const initialState: State = JSON.parse(load(STATE_KEY) || "{}");

const initialAppConfigurationState: AppConfiguration = JSON.parse(load(APP_CONF_KEY) || '{ "workflowId": "quantisal" }');

// Reset the workflow to Quantisal in case there was existing application that opening Oral-eze.
const Store = ({ children }: any) => {
  const [state, dispatch] = useReducer(
    Reducer,
    initialAppConfigurationState.workflowId === "oral-eze"
      ? JSON.parse("{}")
      : initialState
  );
  const [appConfigurationState, setAppConfigurationState] = useState(
    initialAppConfigurationState.workflowId === "oral-eze"
      ? ((JSON.parse(
          '{ "workflowId": "quantisal" }'
        ) as unknown) as AppConfiguration)
      : initialAppConfigurationState
  );

  useEffect(() => {
    save(STATE_KEY, JSON.stringify(state));
  }, [state]);

  useEffect(() => {
    save(APP_CONF_KEY, JSON.stringify(appConfigurationState));
  }, [appConfigurationState]);

  return (
    <Context.Provider value={{ state, dispatch, appConfigurationState, setAppConfigurationState }}>{children}</Context.Provider>
  );
};

export const Context = createContext({
  state: initialState,
  dispatch: (() => {}) as Dispatch<Action>,
  appConfigurationState: initialAppConfigurationState,
  setAppConfigurationState: (() => {}) as Dispatch<React.SetStateAction<AppConfiguration>>
});

export default Store;