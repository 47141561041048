import React from "react";
import {
  Text as StencilText,
  TextProps,
  H1 as StencilH1,
  H2 as StencilH2,
  Label as StencilLabel,
} from "@amzn/stencil-react-components/text";
import {
  Link as StencilLink,
  LinkProps,
} from "@amzn/stencil-react-components/link";
import { labelColor } from "../../config/palette";

export const H1 = ({ children, ...props }: TextProps) => {
  return (
    <StencilH1 fontWeight="regular" fontSize="T600" {...props}>
      {children}
    </StencilH1>
  );
};

export const H2 = ({ children, ...props }: TextProps) => {
  return (
    <StencilH2 fontWeight="regular" fontSize="T500" {...props}>
      {children}
    </StencilH2>
  );
};

export const H3 = ({ children, ...props }: TextProps) => {
  return (
    <StencilH2 fontWeight="bold" fontSize="T400" {...props}>
      {children}
    </StencilH2>
  );
};

export const Text = ({ children, ...props }: TextProps) => {
  return (
    <StencilText fontWeight="regular" fontSize="T400" {...props}>
      {children}
    </StencilText>
  );
};

export const Link = ({ children, ...props }: LinkProps) => {
  return (
    <StencilLink fontSize="T400" {...props}>
      {children}
    </StencilLink>
  );
};

export const Label = ({ children, ...props }: any) => {
  return (
    <StencilLabel fontSize="T400" color={labelColor} {...props}>
      {children}
    </StencilLabel>
  );
};
