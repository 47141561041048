import React, { useCallback, useContext, useEffect, useState } from "react";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { H1, Text } from "../../../components/typography";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  URL_DT_BARCODE_RESULT,
  URL_DT_GUIDE_STICK,
} from "../../../config/urls";
import { useTranslation } from "react-i18next";
import { Button } from "@amzn/stencil-react-components/button";
import { ActionType } from "../../../store/state";
import { Context } from "../../../store/store";
import { EnterBarcodeDialog } from "../dialogs/enter-bar-code";
import { validateSpecimenId } from "../../../utility/validation";
import { inputSequenceReader } from "../../../utility/input-sequence-reader";
import laserScanImage1 from "./scan-1.jpeg";
import laserScanImage2 from "./scan-2.jpeg";
import "./styles.scss";
import { useErrorBanner } from "../../../hooks/use-error-banner";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";

const BARCODE_FALLBACK_TIMER = 15000;
const BARCODE_INCORRECT_ERROR_LIFETIME = 3000;

const readInputSequence = inputSequenceReader();

export const DTBarcodeScanLaserRoute = () => {
  const { state, dispatch } = useContext(Context);
  const { errorBanner, setErrorMessage } = useErrorBanner();
  const [goToBarcodeResult, goBack] = useNavigator(
    URL_DT_BARCODE_RESULT,
    URL_DT_GUIDE_STICK
  );
  const [showBypassButton, setShowBypassButton] = useState(false);
  const [isEnterBarcodeDialogOpen, setIsEnterBarcodeDialogOpen] = useState(
    false
  );
  const { t } = useTranslation();
  const { addPageLoadMetric, addCustomEventMetric } = useAdobeAnalytics();

  useEffect(() => {
    addPageLoadMetric("barcode scan laser");
    // NOTE: This if statement will be removed on build by webpack
    if (process.env.NODE_ENV === "development") {
      setShowBypassButton(true);
    }
    setTimeout(() => setShowBypassButton(true), BARCODE_FALLBACK_TIMER);

    document.body.addEventListener("keypress", onInput);
    return () => {
      document.body.removeEventListener("keypress", onInput);
    };
  }, []);

  const openEnterBarcodeDialog = useCallback(() => {
    setIsEnterBarcodeDialogOpen(true);
  }, []);

  const closeEnterBarcodeDialog = useCallback(() => {
    setIsEnterBarcodeDialogOpen(false);
  }, []);

  const submitBarcode = useCallback(
    (code: string) => {
      dispatch({
        type: ActionType.SetSpecimen,
        payload: {
          id: code,
          labelUsed: state.currentLabel,
        },
      });
      goToBarcodeResult();
    },
    [state.currentLabel]
  );

  const submitBarcodeManually = useCallback(
    (code: string) => {
      addCustomEventMetric({ eventName: "enter barcode manually" });
      submitBarcode(code);
    },
    [submitBarcode]
  );

  const onInput = useCallback(
    async (e: any) => {
      const sequence = await readInputSequence(e.key).promise;
      if (!sequence) {
        return;
      }
      if (validateSpecimenId(sequence)) {
        submitBarcode(sequence);
      } else {
        setErrorMessage(t("dt-barcode-incorrect"));
        setTimeout(() => {
          setErrorMessage("");
        }, BARCODE_INCORRECT_ERROR_LIFETIME);
      }
    },
    [readInputSequence]
  );

  const back = useCallback(() => {
    goBack();
  }, [goBack]);

  const next = useCallback(() => {
    openEnterBarcodeDialog();
  }, []);

    return (
      <Col margin="3rem 0 0 0" gridGap="2rem">
        <H1 fontSize="T600">{t("dt-barcode-title")}</H1>
        <Text>{t("dt-barcode-guidance1")}</Text>
        <Text>{t("dt-barcode-guidance2")}</Text>
        <Row justifyContent="center" gridGap="1rem">
          <img
            alt="Point the scanner machine to scan the barcode"
            src={laserScanImage1}
            className="laser-scan-image"
          />
          <img
            alt="Scan the barcode by pressing the button on the scanner machine"
            src={laserScanImage2}
            className="laser-scan-image"
          />
        </Row>
        <Text>{t("dt-barcode-guidance3")}</Text>
        {errorBanner}
        {showBypassButton && (
          <Row justifyContent="center">
            <Button onClick={next}>{t("dt-barcode-enter_manually")}</Button>
          </Row>
        )}
        <EnterBarcodeDialog
          isOpen={isEnterBarcodeDialogOpen}
          onClose={closeEnterBarcodeDialog}
          onSubmit={submitBarcodeManually}
        />
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={back}>{t("common-back")}</Button>
        </Row>
      </Col>
    );
  
};
