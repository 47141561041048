import React, { useCallback, useState } from "react";
import { H2, Text, Label } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import "./styles.scss";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { validateSpecimenIdDigits } from "../../../utility/validation";

const SPECIMEN_ID_PREFIX = "AMZ";

interface EnterBarcodeDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (barcode: string) => void;
}

export const EnterBarcodeDialog = ({
  isOpen,
  onClose,
  onSubmit,
}: EnterBarcodeDialogProps) => {
  const [barcode, setBarcode] = useState("");
  const [barcodeValid, setBarcodeValid] = useState(true);

  const { t } = useTranslation();

  const reset = useCallback(() => {
    setBarcode("");
    setBarcodeValid(true);
    onClose();
  }, []);

  const onBarcodeChange = useCallback((e) => {
    const barcode = e.target.value;
    const barcodeValid = validateSpecimenIdDigits(barcode);
    setBarcode(barcode);
    setBarcodeValid(barcodeValid);
  }, []);

  const submit = useCallback(() => {
    const barcodeValid = validateSpecimenIdDigits(barcode);

    if (!barcodeValid) {
      setBarcodeValid(barcodeValid);
      return false;
    }

    onSubmit(SPECIMEN_ID_PREFIX + barcode);
    reset();
  }, [barcode]);

  return (
    <Modal isOpen={isOpen} close={reset}>
      <Col gridGap="2rem">
        <H2>{t("dialog-barcode-title")}</H2>
        <Row>
          <div className="barcode-form">
            <div className="barcodeLabel">
              <Row gridGap="2rem">
                <Label htmlFor="barcode">{t("dialog-barcode-label")}</Label>
                <Text fontWeight="bold">{t("dialog-barcode-prefix")}</Text>
              </Row>
            </div>
            <div className="barcode">
              <Input
                id="barcode"
                autoComplete="off"
                type="number"
                width="100%"
                error={!barcodeValid}
                placeholder={t("dialog-barcode-placeholder")}
                value={barcode}
                onChange={onBarcodeChange}
              />
            </div>
            <div className="barcodeValidation">
              {!barcodeValid && (
                <InputFooter warning={true}>
                  {t("dialog-barcode-validation")}
                </InputFooter>
              )}
              <br />
            </div>
          </div>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onClose}>{t("common-cancel")}</Button>
          <Button onClick={submit} variant={ButtonVariant.Primary}>
            {t("common-submit")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
