import React, { useCallback, useContext, useEffect, useState } from "react";
import { H1, Text, Label, Link } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  withTooltip,
  TooltipAlignment,
} from "@amzn/stencil-react-components/tooltip";
import { IconQuestionCircle } from "@amzn/stencil-react-components/icons";
import "./styles.scss";
import { CandidateWrongNameDialog } from "../dialogs/candidate-wrong-name";
import { EditPhoneNumberDialog } from "../dialogs/edit-phone-number";
import { ActionType } from "../../../store/state";
import { Context } from "../../../store/store";
import { updatePhoneNumber } from "../../../service/update-phone-number";
import { useErrorBanner } from "../../../hooks/use-error-banner";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { setActiveCandidateId } from "../../../error-tracker";
import { EditDateOfBirthDialog } from "../dialogs/edit-date-of-birth";
import { updateDateOfBirth } from "../../../service/update-date-of-birth";
import { nextUrl } from "../../../hoc/with-current-workflow-progress";
import { getStage, Stage } from "../../../helpers/stage-info";
import { initRum } from "../../../utility/rum";

const IconWithTooltip = withTooltip()(IconQuestionCircle);

export const DTCandidateConfirmRoute = () => {
  const { state, dispatch } = useContext(Context);
  const [loadingPhone, setLoadingPhone] = useState(false);
  const [loadingDateOfBirth, setLoadingDateOfBirth] = useState(false);
  const { errorBanner, setErrorMessage } = useErrorBanner();
  const [goToCandidateConsent] = useNavigator(nextUrl());
  const [
    isCandidateWrongNameDialogOpen,
    setIsCandidateWrongNameDialogOpen,
  ] = useState(false);
  const [
    isEditPhoneNumberDialogOpen,
    setIsEditPhoneNumberDialogOpen,
  ] = useState(false);
  const [
    isEditDateOfBirthDialogOpen,
    setIsEditDateOfBirthDialogOpen,
  ] = useState(false);

  const { t } = useTranslation();

  const { addPageLoadMetric, addCustomEventMetric } = useAdobeAnalytics();
  const stage = getStage();
  // There might be an unnecessary cost of RUM so don't want to enable it on all the environments
  if (stage == Stage.Gamma || stage == Stage.Prod) {
    initRum();
  }
  useEffect(() => {
    addPageLoadMetric("candidate confirm");
    // This is the moment when we get a candidate id and we should
    // save it for error tracking purposes
    setActiveCandidateId(state.candidate?.candidateId);
  }, []);

  const openCandidateWrongNameDialog = useCallback(() => {
    setIsCandidateWrongNameDialogOpen(true);
  }, []);

  const closeCandidateWrongNameDialog = useCallback(() => {
    setIsCandidateWrongNameDialogOpen(false);
  }, []);

  const openEditPhoneNumberDialog = useCallback(() => {
    setIsEditPhoneNumberDialogOpen(true);
  }, []);

  const closeEditPhoneNumberDialog = useCallback(() => {
    setIsEditPhoneNumberDialogOpen(false);
  }, []);

  const openEditDateOfBirthDialog = useCallback(() => {
    setIsEditDateOfBirthDialogOpen(true);
  }, []);

  const closeEditDateOfBirthDialog = useCallback(() => {
    setIsEditDateOfBirthDialogOpen(false);
  }, []);

  const onPhoneNumberChange = useCallback(async (phoneNumber: string) => {
    addCustomEventMetric({
      eventName: "edit phone number",
    });
    setLoadingPhone(true);
    setErrorMessage("");
    try {
      if (!state.candidate) {
        throw new Error(t("exception-no_candidate_data"));
      }

      await updatePhoneNumber(
        state.candidate.candidateId,
        state.candidate.accessToken,
        state.candidate.drugTestRequestId,
        { phoneNumber }
      );

      dispatch({
        type: ActionType.UpdatePhoneNumber,
        payload: phoneNumber,
      });
    } catch (e) {
      const message =
        e.toTranslatedString?.(t, "updatePhoneNumber") || e.message;
      setErrorMessage(message);
      if (e.code === 410) {
        console.warn("requestId expired!");
        dispatch({
          type: ActionType.ShowExpiredRequestDialog,
          payload: true,
        });
      }
    }
    setLoadingPhone(false);
  }, []);

  const onDateOfBirthChange = useCallback(async (dateOfBirth: string) => {
    addCustomEventMetric({
      eventName: "edit date of birth",
    });
    setLoadingDateOfBirth(true);
    setErrorMessage("");
    try {
      if (!state.candidate) {
        throw new Error(t("exception-no_candidate_data"));
      }

      await updateDateOfBirth(
        state.candidate.candidateId,
        state.candidate.accessToken,
        state.candidate.drugTestRequestId,
        { dateOfBirth }
      );

      dispatch({
        type: ActionType.UpdateDateOfBirth,
        payload: dateOfBirth,
      });
    } catch (e) {
      const message =
        e.toTranslatedString?.(t, "updateDateOfBirth") || e.message;
      setErrorMessage(message);
      if (e.code === 410) {
        console.warn("requestId expired!");
        dispatch({
          type: ActionType.ShowExpiredRequestDialog,
          payload: true,
        });
      }
    }
    setLoadingDateOfBirth(false);
  }, []);

  const confirm = useCallback(() => {
    if (!state.candidate?.phoneNumber) {
      openEditPhoneNumberDialog();
      return;
    }
    if (!state.candidate?.dateOfBirth) {
      openEditDateOfBirthDialog();
      return;
    }
    goToCandidateConsent();
  }, [state.candidate?.phoneNumber]);

  return (
    <>
      <Col margin="3rem 0 0 0" gridGap="3rem" alignItems="center">
        {errorBanner}
        <H1>{t("dt-candidate-confirm-title")}</H1>
        <Row justifyContent="center">
          <div className="user-data-grid">
            <div className="nameLabel">
              <Label>{t("dt-candidate-confirm-name")}</Label>
            </div>
            <div className="name">
              <Text>
                {state.candidate?.firstName} {state.candidate?.lastName}
              </Text>
            </div>
            <div className="wrongName">
              <span className="no-print" onClick={openCandidateWrongNameDialog}>
                <Link>
                  <Text data-testid="edit-candidate-name">
                    {t("dt-candidate-confirm-nameIsWrong")}
                  </Text>
                </Link>
              </span>
            </div>
            <div className="phoneLabel">
              <Label>{t("dt-candidate-confirm-phone")}</Label>
              <IconWithTooltip
                tooltipAlignment={TooltipAlignment.Center}
                tooltipPosition="top"
                tooltipText={t("dt-candidate-confirm-phoneHint")}
              />
            </div>
            <div className="phone">
              {loadingPhone ? (
                <Spinner
                  loadingText={t("dt-candidate-confirm-loadingText")}
                  showText={true}
                  size={SpinnerSize.Small}
                />
              ) : (
                <Text>{state.candidate?.phoneNumber}</Text>
              )}
            </div>
            <div className="editPhone">
              <span className="no-print" onClick={openEditPhoneNumberDialog}>
                <Link>
                  <Text data-testid="edit-candidate-phone-number">
                    {t("dt-candidate-confirm-edit")}
                  </Text>
                </Link>
              </span>
            </div>
            <div className="dateOfBirthLabel">
              <Label>{t("dt-candidate-confirm-dateOfBirth")}</Label>
              <IconWithTooltip
                tooltipAlignment={TooltipAlignment.Center}
                tooltipPosition="top"
                tooltipText={t("dt-candidate-confirm-dateOfBirthHint")}
              />
            </div>
            <div className="dateOfBirth">
              {loadingDateOfBirth ? (
                <Spinner
                  loadingText={t("dt-candidate-confirm-loadingText")}
                  showText={true}
                  size={SpinnerSize.Small}
                />
              ) : (
                <Text>{state.candidate?.dateOfBirth}</Text>
              )}
            </div>
            <div className="editDateOfBirth">
              <span className="no-print" onClick={openEditDateOfBirthDialog}>
                <Link>
                  <Text data-testid="edit-candidate-dob">
                    {t("dt-candidate-confirm-edit")}
                  </Text>
                </Link>
              </span>
            </div>
          </div>
        </Row>
        <Row justifyContent="flex-end">
          <Button
            onClick={confirm}
            variant={ButtonVariant.Primary}
            data-testid="confirm-candidate-data"
          >
            {t("common-confirm")}
          </Button>
        </Row>
      </Col>
      <CandidateWrongNameDialog
        isOpen={isCandidateWrongNameDialogOpen}
        onClose={closeCandidateWrongNameDialog}
      ></CandidateWrongNameDialog>
      <EditPhoneNumberDialog
        isOpen={isEditPhoneNumberDialogOpen}
        onClose={closeEditPhoneNumberDialog}
        onPhoneNumberChange={onPhoneNumberChange}
      ></EditPhoneNumberDialog>
      <EditDateOfBirthDialog
        isOpen={isEditDateOfBirthDialogOpen}
        onClose={closeEditDateOfBirthDialog}
        onChange={onDateOfBirthChange}
      ></EditDateOfBirthDialog>
    </>
  );
};
