import React, { useCallback, useContext, useEffect, useState } from "react";
import { H1, H2 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS,
  URL_DT_BARCODE_SCAN_LASER,
  URL_DT_EXIT,
  URL_DT_GUIDE_LABEL,
} from "../../../config/urls";
import { Card, CardTypes } from "../../../components/card";
import { Context } from "../../../store/store";

import { ActionType } from "../../../store/state";
import { ConfirmDialog } from "../dialogs/confirm";
import "./styles.scss";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { load } from "../../../utility/local-storage";

export const DTGuideStickRoute = () => {
  const { state, dispatch } = useContext(Context);
  const [
    goToBarcodeScanWithLaser,
    goToGuideLabel,
    goToExitDrugTest,
    gotToBarcodeScanWithCamInst,
  ] = useNavigator(
    URL_DT_BARCODE_SCAN_LASER,
    URL_DT_GUIDE_LABEL,
    URL_DT_EXIT,
    URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS
  );
  const [isLabelBDialogOpen, setIsLabelBDialogOpen] = useState(false);
  const [isLabelFailedDialogOpen, setIsLabelFailedDialogOpen] = useState(false);
  const { t } = useTranslation();
  const { addPageLoadMetric, addCustomEventMetric } = useAdobeAnalytics();
  const { appConfigurationState } = useContext(Context);

  const currentLabel = state.currentLabel || "A";
  const scannerMode = load("scannerMode") || "camera";

  const [imageAStep5, setImageAStep5] = useState<any>(null);
  const [imageAWrong1, setImageAWrong1] = useState<any>(null);
  const [imageAWrong2, setImageAWrong2] = useState<any>(null);
  const [imageBStep5, setImageBStep5] = useState<any>(null);
  const [imageBWrong1, setImageBWrong1] = useState<any>(null);
  const [imageBWrong2, setImageBWrong2] = useState<any>(null);
  
  useEffect(() => {
    const getDynamicImports = async () => {
      setImageAStep5(await import(appConfigurationState.guideStick?.images.A.step5 + ""));      
      setImageAWrong1(await import(appConfigurationState.guideStick?.images.A.wrong1 + ""));      
      setImageAWrong2(await import(appConfigurationState.guideStick?.images.A.wrong2 + ""));      
      setImageBStep5(await import(appConfigurationState.guideStick?.images.B.step5 + ""));      
      setImageBWrong1(await import(appConfigurationState.guideStick?.images.B.wrong1 + ""));      
      setImageBWrong2(await import(appConfigurationState.guideStick?.images.B.wrong2 + ""));
    }
    getDynamicImports();
  }, []);

  const images = {
    A: {
      step5: imageAStep5,
      wrong1: imageAWrong1,
      wrong2: imageAWrong2,
    },
    B: {
      step5: imageBStep5,
      wrong1: imageBWrong1,
      wrong2: imageBWrong2,
    },
  };

  useEffect(() => {
    addPageLoadMetric("drug test guide stick");
  }, [addPageLoadMetric]);

  const openLabelBDialog = useCallback(() => {
    setIsLabelBDialogOpen(true);
  }, [setIsLabelBDialogOpen]);

  const closeLabelBDialog = useCallback(() => {
    setIsLabelBDialogOpen(false);
  }, [setIsLabelBDialogOpen]);

  const openLabelFailedDialog = useCallback(() => {
    setIsLabelFailedDialogOpen(true);
  }, [setIsLabelFailedDialogOpen]);

  const closeLabelFailedDialog = useCallback(() => {
    setIsLabelFailedDialogOpen(false);
  }, [setIsLabelFailedDialogOpen]);

  const negative = useCallback(() => {
    if (currentLabel === "B") {
      openLabelFailedDialog();
      return;
    }
    openLabelBDialog();
  }, [openLabelBDialog, openLabelFailedDialog]);

  const next = useCallback(() => {
    if (scannerMode === "laser") {
      goToBarcodeScanWithLaser();
    } else {
      console.log("-----------------------------------------------");
      console.log("gotToBarcodeScanWithCamInst");
      console.log("-----------------------------------------------");
      gotToBarcodeScanWithCamInst();
    }
  }, [goToBarcodeScanWithLaser, gotToBarcodeScanWithCamInst]);

  const useLabelB = useCallback(() => {
    addCustomEventMetric({ eventName: "back to step2" });
    dispatch({
      type: ActionType.SetCurrentLabel,
      payload: "B",
    });
    goToGuideLabel();
  }, [goToGuideLabel, addCustomEventMetric]);

  const proceedToExit = useCallback(() => {
    addCustomEventMetric({ eventName: "miss instruction second time" });
    goToExitDrugTest();
  }, [goToExitDrugTest, addCustomEventMetric]);

    return (
      <Col margin="3rem 0 0 0" gridGap="2rem">
        <H1>{t("dt-guide2-title")}</H1>
        <div className="guide-form-stick">
          <div className="step5">
            <Row justifyContent="center">
              <Card
                title={t("dt-guide3-step5Title")}
                text={t("dt-guide3-step5", { currentLabel })}
                imageHref={images[currentLabel].step5?.default}
              />
            </Row>
          </div>
          <div className="wrong1">
            <Card
              imageHref={images[currentLabel].wrong1?.default}
              type={CardTypes.Wrong}
            />
          </div>
          <div className="wrong2">
            <Card
              imageHref={images[currentLabel].wrong2?.default}
              type={CardTypes.Wrong}
            />
          </div>
        </div>
        <Row justifyContent="center" margin="3rem 0 0 0">
          <H2>{t("dt-guide3-confirmationQuestion", { currentLabel })}</H2>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={negative}>{t("dt-guide3-negative")}</Button>
          <Button onClick={next} variant={ButtonVariant.Primary}>
            {t("dt-guide3-positive")}
          </Button>
        </Row>
        <ConfirmDialog
          title={t("dialog-labelB-title")}
          text={t("dialog-labelB-message")}
          cancelText={t("common-cancel")}
          confirmText={t("dialog-labelB-confirm")}
          isOpen={isLabelBDialogOpen}
          onClose={closeLabelBDialog}
          onConfirm={useLabelB}
        />
        <ConfirmDialog
          title={t("dialog-labelFailed-title")}
          text={t("dialog-labelFailed-message")}
          cancelText={t("common-cancel")}
          confirmText={t("common-ok")}
          isOpen={isLabelFailedDialogOpen}
          onClose={closeLabelFailedDialog}
          onConfirm={proceedToExit}
        />
      </Col>
    );
  
};
