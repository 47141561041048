import { URL_API_DT_GET_COC_DATA } from "../config/urls";
import { fetcher } from "../helpers/fetcher";

export interface GetCoCDataResponse {
  lab: { labId: string; labAccount: string; labPanelId: string };
  employer: { name: string; streetAddress: string; city: string };
  mro: {
    name: string;
    title: string;
    streetAddress: string;
    city: string;
    phoneNumber: string;
    fax: string;
    email: string;
  };
  monitor: {
    name: string;
  };
}

export const getCoCData = async (
  candidateId: string,
  accessToken: string,
  drugTestRequestId: string
): Promise<GetCoCDataResponse> => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("getCoCData", "mocked request");
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve({
            lab: {
              labId: "Quest Diagnostics",
              labAccount: "11078876",
              labPanelId: "25901N",
            },
            employer: {
              name: "Amazon",
              streetAddress: "2021 7th Ave",
              city: "Seattle, WA 98122",
            },
            mro: {
              name: "DAVID NAHIN MD",
              title: "I3Screen",
              streetAddress: "9501 NORTHFIELD BLVD",
              city: "Denver, CO 80238",
              phoneNumber: "PH: 866-405-1416",
              fax: "FAX: 855-253-5666",
              email: "Email: Amazon@i3screen.com",
            },
            monitor: {
              name: "test-monitor",
            },
          });
          // reject(new ServerError("", 401));
        }, 1000);
      });
    }
  }

  const url = URL_API_DT_GET_COC_DATA.replace(
    "{candidateId}",
    candidateId
  ).replace("{drugTestRequestId}", drugTestRequestId);
  return fetcher(url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
