import Cookies from "js-cookie";
const RECRUITER_TOKEN_KEY = "recruiterToken";
import jwtDecode from "jwt-decode";
import { isIntegrationTestMode } from "./local-storage";

// Determines how much time left we should consider acceptable
// to start a new drug test
const TOKEN_MIN_LIFETIME = 1000 * 60 * 30; // 30 min

interface RecruiterToken {
  recruiterName: string;
  exp: number;
  iat: number;
}

const readRecruiterToken = (): RecruiterToken => {
  const token = Cookies.get(RECRUITER_TOKEN_KEY);
  if (!token) {
    throw new Error("No recruiter token");
  }
  return jwtDecode(token) as RecruiterToken;
};

export const saveRecruiterToken = (token: string) => {
  const recruiterToken = jwtDecode(token) as RecruiterToken;
  const expires = new Date(recruiterToken.exp * 1000);
  Cookies.set(RECRUITER_TOKEN_KEY, token, { expires, secure: true });

  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      const distantFuture = new Date(5123123123123);
      Cookies.set(RECRUITER_TOKEN_KEY, token, { expires: distantFuture });
    }
  }
};

const getExpirationDate = () => {
  const recruiterToken = readRecruiterToken();
  return recruiterToken.exp * 1000;
};

export const isTokenExpiringSoon = () => {
  if (isIntegrationTestMode()) return false;

  const expirationDate = getExpirationDate();
  return Date.now() + TOKEN_MIN_LIFETIME > expirationDate;
};

export const isTokenExpired = () => {
  if (isIntegrationTestMode()) return false;

  const expirationDate = getExpirationDate();
  return Date.now() > expirationDate;
};
