import { useContext, useEffect } from "react";
import { Context } from "../../store/store";
import { ActionType } from "../../store/state";
import { URL_CANDIDATE_LOG_IN } from "../../config/urls";
import { useNavigator } from "../../hooks/use-navigator";
import { clearActiveCandidateId } from "../../error-tracker";

export const DTExitRoute = () => {
  const { dispatch } = useContext(Context);
  const [goToCandidateLogIn] = useNavigator(URL_CANDIDATE_LOG_IN);

  useEffect(() => {
    dispatch({
      type: ActionType.ExitDrugTest,
    });
    dispatch({
      type: ActionType.ShowExpiredRequestDialog,
      payload: false,
    });
    goToCandidateLogIn();
    // This is the moment when we we should clear active
    // candidate id and do not associate errors with it
    clearActiveCandidateId();
  }, []);

  return null;
};
