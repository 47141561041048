import React from "react";
import { Col } from "@amzn/stencil-react-components/layout";
import { ProgressBar, ProgressBarStatus } from "@amzn/stencil-react-components/progress-bar";
import { WorkflowStep } from "../../config/workflow-steps";
import { Label } from "@amzn/stencil-react-components/text";
import { withCurrentWorkflowProgress } from "../../hoc/with-current-workflow-progress";
import { useTranslation } from "react-i18next";

export interface WorkflowProgressProps {
  currentIndex: number;
  steps: WorkflowStep[];
}

export const WorkflowProgress = ({
  steps,
  currentIndex,
}: WorkflowProgressProps) => {
  const { t } = useTranslation();
  const currentStep = steps[currentIndex];
  const totalSteps = steps.length;
  const progress = currentIndex / (totalSteps - 1);
  const label =
    t("workflow_step_count") +
    ` ${currentIndex + 1}/${totalSteps} — ${t(currentStep?.label)}`;

  return (
    <Col gridGap="S200">
      <Label fontSize="T300">{label}</Label>
      <ProgressBar progress={progress} status={ProgressBarStatus.Positive} />
    </Col>
  );
};

export const CurrentWorkflowProgress = withCurrentWorkflowProgress(
  WorkflowProgress
);
