interface MetricsWindow {
  dataLayerArray?: any[];
}

const pushLayer = (window: MetricsWindow, metric: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log(metric);
  }
  window.dataLayerArray = window.dataLayerArray || [];
  window.dataLayerArray.push(metric);
};

export const addMetric = (
  window: MetricsWindow,
  event: string,
  props?: any
): void => {
  const metric = {
    event,
    ...props,
  };

  pushLayer(window, metric);
};
