import React, { useCallback, useContext, useState } from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { H1, Text, Label } from "../../components/typography";
import { Col } from "@amzn/stencil-react-components/layout";
import { defaultTextColor } from "../../config/palette";
import { Logo } from "../../components/header";
import { InputFooter, Select } from "@amzn/stencil-react-components/form";
import { Button } from "@amzn/stencil-react-components/button";
import { STATES } from "../../helpers/states";
import { useTranslation } from "react-i18next";
import { Context } from "../../store/store";
import { ActionType } from "../../store/state";
import { useNavigator } from "../../hooks/use-navigator";

import {
  URL_MONITOR_SCAN_QR_CODE,
  URL_MONITOR_SETTINGS,
} from "../../config/urls";
import qrCode from "./qr-code.svg";
import { IconSettings } from "@amzn/stencil-react-components/icons";
import "./styles.scss";

const states = STATES.map((state) => state.name);

export const MonitorSignInRoute = () => {
  const [goToScanQRCode, goToSettings] = useNavigator(
    URL_MONITOR_SCAN_QR_CODE,
    URL_MONITOR_SETTINGS
  );
  const { t } = useTranslation();
  const [stateOfTesting, setStateOfTesting] = useState();
  const [isErrorStateOfTesting, setIsErrorStateOfTesting] = useState(false);
  const [showBarcodeIcon, setShowBarcodeIcon] = useState(false);
  const {
    dispatch,
    appConfigurationState,
    setAppConfigurationState,
  } = useContext(Context);

  const signIn = useCallback(() => {
    if (!stateOfTesting) {
      setIsErrorStateOfTesting(true);
      return;
    }

    const stateOfTestingAbbreviation = STATES.find(
      (state) => state.name === stateOfTesting
    )?.abbreviation;

    setAppConfigurationState({
      ...appConfigurationState,
      workflowId: "quantisal",
    });
    dispatch({
      type: ActionType.SetMonitor,
      payload: {
        stateOfTesting: stateOfTestingAbbreviation,
      },
    });

    goToScanQRCode();
  }, [stateOfTesting]);

  const onStateChange = useCallback((value) => {
    setIsErrorStateOfTesting(false);
    setStateOfTesting(value);
    setShowBarcodeIcon(true);
  }, []);
  const onSettings = useCallback(() => {
    goToSettings();
  }, [goToSettings]);

  return (
    <PageContainer
      color={defaultTextColor}
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Col display="flex">
        <div className="settings">
          <Button onClick={onSettings}>
            <IconSettings />
          </Button>
        </div>

        <Col
          justifyContent="center"
          alignItems="center"
          width="100%"
          gridGap="3rem"
        >
          <Logo />
          <H1 fontSize="T600">{t("monitor-signIn-title")}</H1>
          <div className="sign-in-form">
            <div className="stateOfTestingLabel">
              <Label htmlFor="state">{t("monitor-signIn-state")}</Label>
            </div>
            <div className="stateOfTesting">
              <Select
                id="state"
                error={isErrorStateOfTesting}
                value={stateOfTesting}
                options={states}
                onChange={onStateChange}
                placeholder={t("monitor-signIn-chooseState")}
              />
            </div>
            <div className="stateOfTestingValidation">
              {isErrorStateOfTesting && (
                <InputFooter warning={true}>
                  {t("monitor-signIn-chooseStateValidation")}
                </InputFooter>
              )}
            </div>
          </div>
          <div className="sign-in-form"></div>

          <Text>{t("monitor-signIn-qrInstruction")}</Text>
          {showBarcodeIcon && (
            <Button onClick={signIn} data-testid="show-barcode-monitor-login">
              <img src={qrCode} alt={qrCode}></img>
            </Button>
          )}
        </Col>
      </Col>
    </PageContainer>
  );
};
