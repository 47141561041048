import { Stage } from "../helpers/stage-info";

// ------------------------------ App routes
export const URL_MONITOR_SIGN_IN = "/monitor/sign-in";
export const URL_MONITOR_SCAN_QR_CODE = "/monitor/qr";
export const URL_MONITOR_SIGN_OUT = "/monitor/sign-out";
export const URL_CANDIDATE_LOG_IN = "/candidate/log-in";
export const URL_DT = "/dt";
export const URL_DT_EXIT = "/dt/exit";
export const URL_DT_CANDIDATE_CONFIRM = "/dt/candidate/confirm";
export const URL_DT_CANDIDATE_CONSENT = "/dt/candidate/consent";
export const URL_DT_VIDEO_PRE = "/dt/video/pre";
export const URL_DT_QUANTISAL_PRE = "/dt/quantisal/pre";
export const URL_DT_TIMER = "/dt/timer";
export const URL_DT_VIDEO_POST = "/dt/video/post";
export const URL_DT_QUANTISAL_POST = "/dt/quantisal/post";
export const URL_DT_GUIDE_START = "/dt/guide/start";
export const URL_DT_GUIDE_LABEL = "/dt/guide/label";
export const URL_DT_GUIDE_STICK = "/dt/guide/stick";
export const URL_DT_GUIDE_FINAL = "/dt/guide/final";
export const URL_DT_GUIDE_BAG = "/dt/guide/bag";
export const URL_DT_BARCODE_SCAN_CAMERA = "/dt/barcode/scan/camera";
export const URL_DT_BARCODE_SCAN_LASER = "/dt/barcode/scan/laser";
export const URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS =
  "/dt/barcode/scan/cam/instructions";
export const URL_DT_BARCODE_RESULT = "/dt/barcode/result";
export const URL_DT_FORM = "/dt/form";
export const URL_CLEANING = "/cleaning";
export const URL_TY = "/ty";
export const URL_MONITOR_SETTINGS = "/monitor/settings";

// ------------------------------ DT app routes (relative)
export const URL_DT_EXIT_RELATIVE = "/exit";
export const URL_DT_CANDIDATE_CONFIRM_RELATIVE = "/candidate/confirm";
export const URL_DT_CANDIDATE_CONSENT_RELATIVE = "/candidate/consent";
export const URL_DT_VIDEO_PRE_RELATIVE = "/video/pre";
export const URL_DT_QUANTISAL_PRE_RELATIVE = "/quantisal/pre";
export const URL_DT_TIMER_RELATIVE = "/timer";
export const URL_DT_VIDEO_POST_RELATIVE = "/video/post";
export const URL_DT_QUANTISAL_POST_RELATIVE = "/quantisal/post";
export const URL_DT_GUIDE_START_RELATIVE = "/guide/start";
export const URL_DT_GUIDE_LABEL_RELATIVE = "/guide/label";
export const URL_DT_GUIDE_STICK_RELATIVE = "/guide/stick";
export const URL_DT_GUIDE_FINAL_RELATIVE = "/guide/final";
export const URL_DT_GUIDE_BAG_RELATIVE = "/guide/bag";
export const URL_DT_BARCODE_SCAN_CAMERA_RELATIVE = "/barcode/scan/camera";
export const URL_DT_BARCODE_SCAN_LASER_RELATIVE = "/barcode/scan/laser";
export const URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS_RELATIVE =
  "/barcode/scan/cam/instructions";
export const URL_DT_BARCODE_RESULT_RELATIVE = "/barcode/result";
export const URL_DT_FORM_RELATIVE = "/form";

// ----------------------------- Test routes
export const URL_TEST_CSRF = "/test/csrf/:scenario";

// ----------------------------- API
export const URL_API_LOGGER = "/api/logger";
export const URL_API_CSRF = "/api/csrf";
export const URL_API_METRIC = "/api/candidate/metric";
export const URL_API_MONITOR = "/api/monitor";

// ----------------------------- DT Workflow API
export const URL_API_CANDIDATE_LOG_IN = "/api/candidate/login";
export const URL_API_START_DT = "/api/candidate/{candidateId}/drugTest";
export const URL_API_DT_UPDATE_PHONE_NUMBER =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/phoneNumber";
export const URL_API_DT_UPDATE_DATE_OF_BIRTH =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/dateOfBirth";
export const URL_API_DT_GET_CONSENTS =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/consents";
export const URL_API_DT_CONSENT =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/consent";
export const URL_API_DT_COLLECT_SPECIMEN =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/collectSpecimen";
export const URL_API_DT_GET_COC_DATA =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/cocData";
export const URL_API_DT_SUBMIT_COC =
  "/api/candidate/{candidateId}/drugTest/{drugTestRequestId}/coc";

// ----------------------------- VIDEO URLS
export const URL_VIDEO_ECOC_DT_1 =
  "https://{stageDomain}assets.ecoc.associate.amazondelivers.jobs/eCOC_DT_1.mp4";
export const URL_VIDEO_ECOC_DT_2 =
  "https://{stageDomain}assets.ecoc.associate.amazondelivers.jobs/eCOC_DT_2.mp4";

export const URL_VIDEO_ECOC_DT_1_PROXY =
  "https://fast-fjord-58362.herokuapp.com/https://{stageDomain}assets.ecoc.associate.amazondelivers.jobs/eCOC_DT_1.mp4";
export const URL_VIDEO_ECOC_DT_2_PROXY =
  "https://fast-fjord-58362.herokuapp.com/https://{stageDomain}assets.ecoc.associate.amazondelivers.jobs/eCOC_DT_2.mp4";

// ----------------------------- Adobe Analytics
export const URL_ADOBE_DATA_LAYER_SCRIPT: Record<Stage, string> = {
  [Stage.Beta]:
    "https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js",
  [Stage.Gamma]:
    "https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js",
  [Stage.Prod]:
    "https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js",
};
