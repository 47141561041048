import { Stage } from "../helpers/stage-info";
export const RECRUITER_TOKEN_KEY = "recruiterToken";

export type RumConfigType = {
  config: {
    sessionSampleRate: number;
    guestRoleArn: string;
    identityPoolId: string;
    endpoint: string;
    telemetries: string[];
    allowCookies: boolean;
    enableXRay: boolean;
    candidateId?: string;
  },
  applicationId: string;
  applicationVersion: string;
  applicationRegion: string;
};
export const RumConfig: Record<string, RumConfigType> = {
  [Stage.Gamma]: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::581370357896:role/RUM-Monitor-us-east-1-581370357896-6125426436861-Unauth",
      identityPoolId: "us-east-1:5e54b3d5-f075-4909-8ae9-27a51c6fcc2b",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    },
    applicationId: "38c17425-0385-4542-a858-50ac5b43eb5f",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-1"
  },
  [Stage.Prod]: {
    config: {
      sessionSampleRate: 1,
      guestRoleArn: "arn:aws:iam::363213067999:role/RUM-Monitor-us-east-1-363213067999-6554963536861-Unauth",
      identityPoolId: "us-east-1:53805f3d-46d1-4d2e-ab2b-f23301c89931",
      endpoint: "https://dataplane.rum.us-east-1.amazonaws.com",
      telemetries: ["performance","errors","http"],
      allowCookies: true,
      enableXRay: false
    },
    applicationId: "b13f6606-90bd-48b6-8791-f31123b8da24",
    applicationVersion: "1.0.0",
    applicationRegion: "us-east-1"
  }
};
