import { URL_API_DT_CONSENT } from "../config/urls";
import { fetcher } from "../helpers/fetcher";

export interface ConsentSigned {
  name: string;
  content: string;
}

export interface ConsentPayload {
  consents: Array<ConsentSigned>;
  date: string;
}

export const consent = async (
  candidateId: string,
  accessToken: string,
  drugTestRequestId: string,
  payload: ConsentPayload
) => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("consent", "mocked request", payload);
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
          // reject(new ServerError("", 500));
        }, 1000);
      });
    }
  }

  const url = URL_API_DT_CONSENT.replace("{candidateId}", candidateId).replace(
    "{drugTestRequestId}",
    drugTestRequestId
  );

  return fetcher(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
