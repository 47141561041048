import React, { useCallback, useEffect } from "react";
import { H1 } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { URL_CLEANING, URL_TY } from "../../../config/urls";
import bag from "./bag.jpeg";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";

export const DTGuideBag = () => {
  const [goToTY, goBack] = useNavigator(URL_TY, URL_CLEANING);
  const { t } = useTranslation();
  const { addPageLoadMetric, addGoBackEventMetric } = useAdobeAnalytics();

  useEffect(() => {
    addPageLoadMetric("drug test guide bag");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addGoBackEventMetric]);

  const next = useCallback(() => {
    goToTY();
  }, []);

  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      <H1 fontSize="T500">{t("dt-guide5-title")}</H1>
      <img alt="Amazon bag" src={bag} />
      <Row justifyContent="flex-end" gridGap="1rem">
        <Button onClick={back}>{t("common-back")}</Button>
        <Button onClick={next} variant={ButtonVariant.Primary}>
          {t("common-next")}
        </Button>
      </Row>
    </Col>
  );
};
