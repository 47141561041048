/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactMarkdown from "react-markdown";
import dayjs from "dayjs";
import { H1, Text, Label } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";

import { Consent } from "../../../service/get-consents";
import { ActionType } from "../../../store/state";
import { Context } from "../../../store/store";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { LoadingButton } from "../../../components/loading-button";
import { consent } from "../../../service/consent";
import { useErrorBanner } from "../../../hooks/use-error-banner";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { previousUrl, nextUrl } from "../../../hoc/with-current-workflow-progress";

export const DTCandidateConsentsRoute = () => {
  const { state, dispatch } = useContext(Context);
  const contestHtmlRef = useRef<HTMLDivElement>(null);
  const { errorBanner, setErrorMessage, hasError } = useErrorBanner();
  const [goToVideoPre, goBack] = useNavigator(
    nextUrl(),
    previousUrl()
  );
  
  const [date, setDate] = useState("");
  const [loading, setLoading] = useState(false);
  const [consents, setConsents] = useState<Array<Consent> | null>(null);
  const [consentIndex, setConsentIndex] = useState(0);
  const { t } = useTranslation();
  const { addPageLoadMetric, addGoBackEventMetric } = useAdobeAnalytics();


  useEffect(() => {
    
    const loadConsents = async () => {
      try {
        if (!state.candidate) {
          throw new Error(t("exception-no_candidate_data"));
        }

        const consents = [
          {
            name: t("dt-candidate-consent-generalAcknowledgement-name"),
            content: t("dt-candidate-consent-generalAcknowledgement-content"),
          },
          {
            name: t("dt-candidate-consent-electricSignatureConsent-name"),
            content: t("dt-candidate-consent-electricSignatureConsent-content"),
          },
        ];

        if (state.candidate.stateOfTesting === "NC") {
          consents.push({
            name: t("dt-candidate-consent-ncExamConsent-name"),
            content: t("dt-candidate-consent-ncExamConsent-content"),
          })
        }

        setConsents(consents);
        setConsentIndex(0);
        setDate(dayjs(new Date()).format("MM/DD/YYYY"));
      } catch (e) {
        const message = e.toTranslatedString?.(t) || e.message;
        setErrorMessage(message);
        if (e.code === 410) {
          console.warn("requestId expired!");
          dispatch({
            type: ActionType.ShowExpiredRequestDialog,
            payload: true,
          });
        }
      }
    };
    loadConsents();
  }, []);

  useEffect(() => {
    addPageLoadMetric(`drug test consent ${consentIndex + 1}`);
  }, [consentIndex]);

  const next = useCallback(async () => {
    if (!consents) {
      return;
    }
    consents[consentIndex].html = contestHtmlRef.current?.innerHTML;
    if (consentIndex < consents.length - 1) {
      setConsentIndex(consentIndex + 1);
      return;
    }

    setLoading(true);
    try {
      if (!state.candidate) {
        throw new Error(t("exception-no_candidate_data"));
      }

      await consent(
        state.candidate.candidateId,
        state.candidate.accessToken,
        state.candidate.drugTestRequestId,
        {
          consents: consents.map((consent) => ({
            name: consent.name,
            content: consent.html!,
          })),
          date,
        }
      );
      goToVideoPre();
    } catch (e) {
      setLoading(false);
      const message = e.toTranslatedString?.(t) || e.message;
      setErrorMessage(message);
      if (e.code === 410) {
        console.warn("requestId expired!");
        dispatch({
          type: ActionType.ShowExpiredRequestDialog,
          payload: true,
        });
      }
    }
  }, [goToVideoPre, consents, consentIndex]);

  const back = useCallback(() => {
    addGoBackEventMetric();
    if (consentIndex === 0) {
      goBack();
      return;
    }
    setConsentIndex(consentIndex - 1);
  }, [goBack, consentIndex]);

  let content;

  if (!consents) {
    if (hasError) {
      content = null;
    } else {
      content = (
        <Col
          className="loading-page-wrapper"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size={SpinnerSize.Large}></Spinner>
        </Col>
      );
    }
  } else {
    const currentConsent = consents[consentIndex];
    content = (
      <>
        <H1 fontSize="T500">
          {t("dt-candidate-consent-title", {
            index: consentIndex + 1,
            total: consents.length,
            title: currentConsent.name,
          })}
        </H1>
        <div ref={contestHtmlRef} className="consent-html">
          <ReactMarkdown>{currentConsent!.content}</ReactMarkdown>
        </div>
        <Row gridGap="12rem">
          <Label fontSize="T300">{t("dt-candidate-consent-date")}</Label>
          <Text fontSize="T300">{date}</Text>
        </Row>
        <Row
          justifyContent="flex-end"
          gridGap="1rem"
          margin="3rem 0 0 0"
          alignItems="center"
        >
          <Button onClick={back}>{t("common-back")}</Button>
          <LoadingButton onClick={next} variant={ButtonVariant.Primary} loading={loading}>
            {t("dt-candidate-consent-buttonSubmit")}
          </LoadingButton>
        </Row>
      </>
    );
  }

  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      {errorBanner}
      {content}
    </Col>
  );
};

