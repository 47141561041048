import { AwsRum } from 'aws-rum-web';
import { getStage } from "../helpers/stage-info";
import { RumConfig } from "../config/constants";
import { useContext } from "react";
import { Context } from "../store/store";

export const initRum = (): void => {
  try {
    console.log("Initializing RUM");
    const stage = getStage();
    const rumConfig = RumConfig[stage];

    if (rumConfig) {
      const awsRum = new AwsRum(
        rumConfig.applicationId,
        rumConfig.applicationVersion,
        rumConfig.applicationRegion,
        rumConfig.config
      );
      const { state } = useContext(Context);
      awsRum.addSessionAttributes({
        candidateId: state.candidate?.candidateId || ""
      })
      console.log("RUM Initialized");
    } else {
      console.log("RUM config not found for stage: " + stage);
    }
  } catch (error) {
    console.error('Error initializing RUM client', error);
  }
}