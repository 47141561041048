export class Deferred {
  resolve: any;
  promise: Promise<any> | undefined;

  constructor() {
    const self: any = this || {};
    self.promise = new Promise((resolve) => {
      self.resolve = resolve;
    });
    return self;
  }
}
