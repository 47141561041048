import React from "react";

import {
  URL_DT_BARCODE_RESULT_RELATIVE,
  URL_DT_BARCODE_SCAN_CAMERA_RELATIVE,
  URL_DT_BARCODE_SCAN_LASER_RELATIVE,
  URL_DT_CANDIDATE_CONFIRM_RELATIVE,
  URL_DT_CANDIDATE_CONSENT_RELATIVE,
  URL_DT_EXIT_RELATIVE,
  URL_DT_FORM_RELATIVE,
  URL_DT_GUIDE_BAG_RELATIVE,
  URL_DT_GUIDE_FINAL_RELATIVE,
  URL_DT_GUIDE_LABEL_RELATIVE,
  URL_DT_GUIDE_START_RELATIVE,
  URL_DT_GUIDE_STICK_RELATIVE,
  URL_DT_TIMER_RELATIVE,
  URL_DT_VIDEO_POST_RELATIVE,
  URL_DT_VIDEO_PRE_RELATIVE,
  URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS_RELATIVE,
} from "../../config/urls";

import { DTCandidateConfirmRoute } from "../../routes/dt/candidate/confirm";
import { Route, Routes } from "react-router-dom";
import { DTExitRoute } from "../../routes/dt/exit";
import { DTCandidateConsentsRoute } from "../../routes/dt/candidate/consents";
import { DTVideoPreRoute } from "../../routes/dt/video/pre";
import { DTVideoPostRoute } from "../../routes/dt/video/post";
import { DTTimerRoute } from "../../routes/dt/timer";
import { DTGuideStartRoute } from "../../routes/dt/guide/guide-start";
import { DTGuideStickRoute } from "../../routes/dt/guide/guide-stick";
import { DTGuideFinal } from "../../routes/dt/guide/guide-final";
import { DTBarcodeScanCameraRoute } from "../../routes/dt/barcode/scan-camera";
import { DTBarcodeScanCameraInstructionsRoute } from "../../routes/dt/barcode/scan-camera-instructions";
import { DTBarcodeResultRoute } from "../../routes/dt/barcode/result";
import { DTFormRoute } from "../../routes/dt/form";
import { DTGuideBag } from "../../routes/dt/guide/guide-bag";
import { DTGuideLabelRoute } from "../../routes/dt/guide/guide-label";
import { DTBarcodeScanLaserRoute } from "../../routes/dt/barcode/scan-laser";

export const OralEzeInternal = () => {
  return (
    <Routes>
      <Route
        path={URL_DT_CANDIDATE_CONFIRM_RELATIVE}
        element={<DTCandidateConfirmRoute />}
      />
      <Route path={URL_DT_EXIT_RELATIVE} element={<DTExitRoute />} />
      <Route
        path={URL_DT_CANDIDATE_CONSENT_RELATIVE}
        element={<DTCandidateConsentsRoute />}
      />
      <Route path={URL_DT_VIDEO_PRE_RELATIVE} element={<DTVideoPreRoute />} />
      <Route path={URL_DT_TIMER_RELATIVE} element={<DTTimerRoute />} />
      <Route path={URL_DT_VIDEO_POST_RELATIVE} element={<DTVideoPostRoute />} />
      <Route
        path={URL_DT_GUIDE_START_RELATIVE}
        element={<DTGuideStartRoute />}
      />
      <Route
        path={URL_DT_GUIDE_LABEL_RELATIVE}
        element={<DTGuideLabelRoute />}
      />
      <Route
        path={URL_DT_GUIDE_STICK_RELATIVE}
        element={<DTGuideStickRoute />}
      />
      <Route
        path={URL_DT_BARCODE_SCAN_LASER_RELATIVE}
        element={<DTBarcodeScanLaserRoute />}
      />
      <Route
        path={URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS_RELATIVE}
        element={<DTBarcodeScanCameraInstructionsRoute />}
      />
      <Route
        path={URL_DT_BARCODE_SCAN_CAMERA_RELATIVE}
        element={<DTBarcodeScanCameraRoute />}
      />
      <Route
        path={URL_DT_BARCODE_RESULT_RELATIVE}
        element={<DTBarcodeResultRoute />}
      />
      <Route path={URL_DT_GUIDE_FINAL_RELATIVE} element={<DTGuideFinal />} />
      <Route path={URL_DT_GUIDE_BAG_RELATIVE} element={<DTGuideBag />} />
      <Route path={URL_DT_FORM_RELATIVE} element={<DTFormRoute />} />
    </Routes>
  );
};
