import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  H1,
  H2 as OriginalH2,
  Text as OriginalText,
  Label as OriginalLabel,
} from "../../../components/typography";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { TextProps } from "@amzn/stencil-react-components/text";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  URL_DT_GUIDE_FINAL,
  URL_DT_BARCODE_RESULT,
} from "../../../config/urls";
import "./styles.scss";
import { SignatureImage } from "../../../components/signature/image";
import { Checkbox } from "@amzn/stencil-react-components/form";
import { ActionType } from "../../../store/state";
import { Context } from "../../../store/store";
import dayjs from "dayjs";
import { getCoCData } from "../../../service/get-coc-data";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { LoadingButton } from "../../../components/loading-button";
import { useErrorBanner } from "../../../hooks/use-error-banner";
import { submitCoC } from "../../../service/submit-coc";
import { errorColor } from "../../../config/palette";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";

import html2canvas from "html2canvas";
import i18n from "../../../utility/i18n";

const H2 = ({ children, ...props }: TextProps) => {
  return (
    <OriginalH2
      fontWeight="bold"
      fontSize="T400"
      margin="1rem 0 0.5rem 0"
      {...props}
    >
      {children}
    </OriginalH2>
  );
};

export const Text = ({ children, ...props }: TextProps) => {
  return (
    <OriginalText fontWeight="regular" fontSize="T300" {...props}>
      {children}
    </OriginalText>
  );
};

const Label = ({ children, ...props }: any) => {
  return (
    <OriginalLabel fontSize="T300" {...props}>
      {children}
    </OriginalLabel>
  );
};

const filterNoPrintElements = (node: any) => {
  return node.classList.contains("no-print");
};

export const DTFormRoute = () => {
  const [goToURLGuideFinal, goBack] = useNavigator(
    URL_DT_GUIDE_FINAL,
    URL_DT_BARCODE_RESULT
  );
  const { state, dispatch } = useContext(Context);
  const [hasSignature, setHasSignature] = useState(false);
  const [signatureErrorState, setSignatureErrorState] = useState(false);
  const [formData, setFormData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const { errorBanner, setErrorMessage, hasError } = useErrorBanner();
  const { t } = useTranslation();
  const {
    addPageLoadMetric,
    addCustomEventMetric,
    addGoBackEventMetric,
  } = useAdobeAnalytics();

  const submit = useCallback(async () => {
    if (!hasSignature) {
      setSignatureErrorState(true);
      return;
    }

    try {
      if (!state.candidate) {
        throw new Error(t("exception-no_candidate_data"));
      }
      setLoading(true);
      setErrorMessage("");

      const node = document.querySelector(".StickyHeader") as HTMLElement;
      node?.classList.remove("StickyHeader");

      const dataUrl = await html2canvas(document.body, {
        ignoreElements: filterNoPrintElements,
      }).then((canvas) => {
        return canvas.toDataURL();
      });

      node?.classList.add("StickyHeader");

      await submitCoC(
        state.candidate.candidateId,
        state.candidate.accessToken,
        state.candidate.drugTestRequestId,
        {
          cocImage: dataUrl,
          collectionDateTime: formData.collectionDateTime,
          language: i18n.language,
        }
      );
      goToURLGuideFinal();
    } catch (e) {
      setLoading(false);
      const message = e.toTranslatedString?.(t) || e.message;
      setErrorMessage(message);
      if (e.code === 410) {
        console.warn("requestId expired!");
        dispatch({
          type: ActionType.ShowExpiredRequestDialog,
          payload: true,
        });
      }
    }
  }, [hasSignature]);

  const updateSignature = useCallback(() => {
    addCustomEventMetric({
      eventName: "draw signature on drug test form page",
    });
    setHasSignature(true);
    setSignatureErrorState(false);
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addGoBackEventMetric]);

  useEffect(() => {
    addPageLoadMetric("drug test form");

    const requestCoCData = async () => {
      const { candidate, specimen, monitor } = state;

      try {
        if (!specimen) {
          throw new Error(t("exception-no_specimen_data"));
        }
        if (!candidate) {
          throw new Error(t("exception-no_candidate_data"));
        }
        if (!monitor) {
          throw new Error(t("exception-no_monitor_data"));
        }

        const cocData = await getCoCData(
          candidate.candidateId,
          candidate.accessToken,
          candidate.drugTestRequestId
        );

        const formData = {
          specimenId: specimen.id,
          labId: cocData.lab.labId,
          labAccount: cocData.lab.labAccount,
          labPanelId: cocData.lab.labPanelId,

          id: candidate.candidateId,
          lastName: candidate.lastName,
          firstName: candidate.firstName,
          name: `${candidate.firstName} ${candidate.lastName}`,
          idVerified: true,
          reasonForTest: true,
          phoneNumber: candidate.phoneNumber,
          dateOfBirth: candidate.dateOfBirth,
          monitorName: cocData.monitor.name,
          collectionDateTime: dayjs(new Date()).format("MM/DD/YYYY HH:mm"),
          employer: [
            cocData.employer.name,
            cocData.employer.streetAddress,
            cocData.employer.city,
          ]
            .filter((item) => !!item)
            .join("\n"),
          medicalReviewOfficer: [
            cocData.mro.name,
            cocData.mro.title,
            cocData.mro.streetAddress,
            cocData.mro.city,
            cocData.mro.phoneNumber,
            cocData.mro.fax,
            cocData.mro.email,
          ]
            .filter((item) => !!item)
            .join("\n"),
        };
        setFormData(formData);
      } catch (e) {
        const message = e.toTranslatedString?.(t) || e.message || e;
        setErrorMessage(message);
        if (e.code === 410) {
          console.warn("requestId expired!");
          dispatch({
            type: ActionType.ShowExpiredRequestDialog,
            payload: true,
          });
        }
      }
    };

    requestCoCData();
  }, []);

  let content;

  if (!formData) {
    if (hasError) {
      content = null;
    } else {
      content = (
        <Col
          className="loading-page-wrapper"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size={SpinnerSize.Large}></Spinner>
        </Col>
      );
    }
  } else {
    content = (
      <>
        <Col alignItems="center" >

          <Text color={errorColor}>{t("dt-form-disclaimer")}</Text>

        </Col>
        <div className="form-header">
          <div className="labelSpecimenId">
            <Label>{t("dt-form-specimenId")}</Label>
          </div>
          <div className="specimenId">
            <Text>{formData.specimenId}</Text>
          </div>
          <div className="labIdLabel">
            <Label>{t("dt-form-labId")}</Label>
          </div>
          <div className="labId">
            <Text>{formData.labId}</Text>
          </div>
          <div className="labAccountLabel">
            <Label>{t("dt-form-labAccount")}</Label>
          </div>
          <div className="labAccount">
            <Text>{formData.labAccount}</Text>
          </div>
          <div className="labPanelIdLabel">
            <Label>{t("dt-form-labPanelId")}</Label>
          </div>
          <div className="labPanelId">
            <Text>{formData.labPanelId}</Text>
          </div>
        </div>
        <H1
          textAlign="center"
          margin="2rem 0 0 0"
          fontWeight="medium"
          fontSize="T500"
        >
          {t("dt-form-title")}
        </H1>
        <div className="form-body">
          <div className="donorInformation">
            <H2>{t("dt-form-donor")}</H2>
          </div>
          <div className="idLabel">
            <Label>{t("dt-form-id")}</Label>
          </div>
          <div className="id">
            <Text>{formData.id}</Text>
          </div>
          <div className="lastNameLabel">
            <Label>{t("dt-form-lastName")}</Label>
          </div>
          <div className="lastName">
            <Text>{formData.lastName}</Text>
          </div>
          <div className="firstNameLabel">
            <Label>{t("dt-form-firstName")}</Label>
          </div>
          <div className="firstName">
            <Text>{formData.firstName}</Text>
          </div>
          <div className="idVerifiedLabel">
            <Label>{t("dt-form-idVerified")}</Label>
          </div>
          <div className="idVerified">
            <Row gridGap="0.5rem">
              <Checkbox defaultChecked={true} id="idVerified" disabled={true} />
              <Label htmlFor="idVerified">{t("dt-form-photoId")}</Label>
            </Row>
          </div>
          <div className="reasonForTestLabel">
            <Label>{t("dt-form-reasonForTest")}</Label>
          </div>
          <div className="reasonForTest">
            <Row gridGap="0.5rem">
              <Checkbox
                defaultChecked={true}
                id="reasonForTest"
                disabled={true}
              />
              <Label htmlFor="reasonForTest">
                {t("dt-form-preEmployment")}
              </Label>
            </Row>
          </div>
          <div className="phoneNumberLabel">
            <Label>{t("dt-form-phoneNumber")}</Label>
          </div>
          <div className="phoneNumber">
            <Text>{formData.phoneNumber}</Text>
          </div>
          <div className="dateOfBirthLabel">
            <Label>{t("dt-form-dateOfBirth")}</Label>
          </div>
          <div className="dateOfBirth">
            <Text>{formData.dateOfBirth}</Text>
          </div>
          <div className="collectionInformation">
            <H2>{t("dt-form-collectionInformation")}</H2>
          </div>
          <div className="monitorNameLabel">
            <Label>{t("dt-form-monitorName")}</Label>
          </div>
          <div className="monitorName">
            <Text>{formData.monitorName}</Text>
          </div>
          <div className="collectionDateTimeLabel">
            <Label>{t("dt-form-collectionDateTime")}</Label>
          </div>
          <div className="collectionDateTime">
            <Text>{formData.collectionDateTime}</Text>
          </div>
          <div className="employerLabel">
            <H2>{t("dt-form-employer")}</H2>
          </div>
          <div className="medicalReviewOfficerLabel">
            <H2>{t("dt-form-medicalReviewOfficer")}</H2>
          </div>
          <div className="employer">
            <Text>{formData.employer}</Text>
          </div>
          <div className="medicalReviewOfficer">
            <Text>{formData.medicalReviewOfficer}</Text>
          </div>
          <div className="note">
            <Text fontSize="T200">{t("dt-form-note")}</Text>
          </div>
          <div className="nameLabel">
            <Label>{t("dt-form-namePrint")}</Label>
          </div>
          <div className="name">
            <Text>{formData.name}</Text>
          </div>
          <div className="signatureLabel">
            <Label>{t("dt-form-signature")}</Label>
          </div>
          <div className="signature">
            <SignatureImage
              onSignatureChange={updateSignature}
              errorState={signatureErrorState}
            />
          </div>
          {signatureErrorState && (
            <div className="signatureValidation">
              <Text margin="0 0 0 12px" fontSize="T200" color={errorColor}>
                {t("dt-form-signatureValidation")}
              </Text>
            </div>
          )}
        </div>

        <Row
          justifyContent="flex-end"
          gridGap="1rem"
          className="no-print"
          margin="3rem 0 0 0"
        >
          <Button onClick={back}>{t("common-back")}</Button>
          <LoadingButton onClick={submit} variant={ButtonVariant.Primary} loading={loading}>
            {t("common-submit")}
          </LoadingButton>
        </Row>
      </>
    );
  }
    return (
      <Col
        className="form-container"
        margin="3rem 0 0 0"
        gridGap="1rem"
        width="100%"
      >
        {errorBanner}
        {content}
      </Col>
    );
  
};
