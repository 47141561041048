export const getInitialData = () => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    return {
      email: "hvh-mew-dev+blackbirdcandidate@amazon.com",
      phoneTail: "6789",
    };
  }
  return {
    email: "",
    phoneTail: "",
  };
};
