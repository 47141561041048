import React, { useCallback, useState } from "react";
import { H2, Label } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import "./styles.scss";
import { InputFooter } from "@amzn/stencil-react-components/form";
import { validateDateOfBirth } from "../../../utility/validation";
import ReactInputDateMask from "react-input-date-mask";

interface EditDateOfBirthDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: (dateOfBirth: string) => void;
}

export const EditDateOfBirthDialog = ({
  isOpen,
  onClose,
  onChange,
}: EditDateOfBirthDialogProps) => {
  const [newDateOfBirth, setNewDateOfBirth] = useState("");
  const [newDateOfBirthValid, setNewDateOfBirthValid] = useState(true);

  const { t } = useTranslation();

  const reset = useCallback(() => {
    setNewDateOfBirth("");
    setNewDateOfBirthValid(true);
    onClose();
  }, []);

  const onNewDateOfBirthChange = useCallback((dateOfBirth) => {
    const isDateOfBirthValid = validateDateOfBirth(dateOfBirth);
    setNewDateOfBirth(dateOfBirth);
    setNewDateOfBirthValid(isDateOfBirthValid);
    return isDateOfBirthValid;
  }, []);

  const submit = useCallback(() => {
    if (!onNewDateOfBirthChange(newDateOfBirth)) {
      return;
    }

    onChange(newDateOfBirth);
    reset();
  }, [newDateOfBirth]);

  return (
    <Modal isOpen={isOpen} close={reset}>
      <Col gridGap="2rem">
        <H2>{t("dialog-dateOfBirth-title")}</H2>
        <Row>
          <div className="date-of-birth-form">
            <div className="newDateOfBirthLabel">
              <Label htmlFor="newDateOfBirth">
                {t("dialog-dateOfBirth-label")}
              </Label>
            </div>
            <div className="newDateOfBirth">
              <ReactInputDateMask
                id="newDateOfBirth"
                mask="mm/dd/yyyy"
                className={`input-field${
                  !newDateOfBirthValid ? " error-state" : ""
                }`}
                value={newDateOfBirth}
                onChange={onNewDateOfBirthChange}
              />
            </div>
            <div className="newDateOfBirthValidation">
              {!newDateOfBirthValid && (
                <InputFooter warning={true}>
                  {t("dialog-dateOfBirth-validation")}
                </InputFooter>
              )}
              <br />
            </div>
          </div>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={onClose}>{t("common-cancel")}</Button>
          <Button onClick={submit} variant={ButtonVariant.Primary} data-testid="confirm-dob">
            {t("common-confirm")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
