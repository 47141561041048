import { readCsrfToken } from "@amzn/hvh_simple_hire_common/dist/csrf/client";
import { CSRF_KEY } from "@amzn/hvh_simple_hire_common/dist/csrf/common";

const METHODS_PROTECTED_BY_CSRF = ["POST", "PUT", "PATCH", "DELETE"];

export class ApiCallError extends Error {
  code: number;
  apiCallName: string | undefined;
  constructor(message: string, code: number, url?: string) {
    super(message);
    this.code = code;
    this.apiCallName = url?.split("/").pop();
  }
  toTranslatedString(t: any, apiCallName?: string) {
    const specificErrorMessageKey = `response-${
      apiCallName || this.apiCallName
    }-${this.code}`;
    const specificErrorMessage = t(specificErrorMessageKey);
    if (
      specificErrorMessage &&
      specificErrorMessage !== specificErrorMessageKey
    ) {
      return specificErrorMessage;
    }
    const commonErrorMessageKey = `response-${this.code}`;
    const commonErrorMessage = t(commonErrorMessageKey);
    if (commonErrorMessage && commonErrorMessage !== commonErrorMessageKey) {
      return commonErrorMessage;
    }
    return t(`response-fallback`);
  }
}

const addCsrf = (init?: RequestInit): RequestInit | undefined => {
  const method = init?.method?.toUpperCase();
  if (init && method && METHODS_PROTECTED_BY_CSRF.includes(method)) {
    init.headers = {
      [CSRF_KEY]: readCsrfToken(),
      ...init.headers,
    };
  }
  return init;
};

export const adjustResourceUrl = (resource: RequestInfo): RequestInfo => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    return "https://beta.pdx.ecoc.associate.amazondelivers.jobs" + resource;
  }
  return resource;
};

const handleErrors = async (response: Response) => {
  if (!response.ok) {
    const body = await getBody(response);
    throw new ApiCallError(body, response.status, response.url);
  }
  return response;
};

const getBody = (response: Response) => {
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    return response.json();
  } else {
    return response.text();
  }
};

export const fetcher = async (resource: RequestInfo, init?: RequestInit) => {
  // TODO: Uncomment when CSRF is implemented on the backend
  // init = addCsrf(init);
  console.warn("addCsrf", "is not implemented", !!addCsrf);

  if (!window.navigator.onLine) {
    throw new ApiCallError("", 503);
  }
  resource = adjustResourceUrl(resource);
  const response = await fetch(resource, init).then((response) =>
    handleErrors(response)
  );
  return getBody(response);
};
