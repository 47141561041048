/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback, useContext, useRef, useState } from "react";
import { Camera, CameraMode } from "@amzn/hvh-camera-ui";
import { Row } from "@amzn/stencil-react-components/layout";
import { Link, Text } from "../../../components/typography";
import { useNavigator } from "../../../hooks/use-navigator";
import {
  URL_DT_BARCODE_RESULT,
  URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS,
} from "../../../config/urls";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import { Button } from "@amzn/stencil-react-components/button";
import { ActionType } from "../../../store/state";
import { Context } from "../../../store/store";
import { EnterBarcodeDialog } from "../dialogs/enter-bar-code";
import beep from "../../../media/beep.mp3";
import { detectBarcodeQuagga } from "../../../utility/barcode-detection";
import { extractBarCodeImage } from "../../../helpers/extract-bar-code-image";
import { validateSpecimenId } from "../../../utility/validation";
import { ScanTarget } from "../../../components/scan-target";

const BARCODE_FALLBACK_TIMER = 15000;

export const DTBarcodeScanCameraRoute = () => {
  const { state, dispatch, appConfigurationState } = useContext(Context);
  const beepRef = useRef<HTMLAudioElement>();
  const [goToBarcodeResult, goBack] = useNavigator(
    URL_DT_BARCODE_RESULT,
    URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS
  );
  const [cameraReady, setCameraReady] = useState(false);
  const [showManualOption, setShowManualOption] = useState(false);
  const [isEnterBarcodeDialogOpen, setIsEnterBarcodeDialogOpen] = useState(
    false
  );
  const { t } = useTranslation();

  const openEnterBarcodeDialog = useCallback(() => {
    setIsEnterBarcodeDialogOpen(true);
  }, []);

  const closeEnterBarcodeDialog = useCallback(() => {
    setIsEnterBarcodeDialogOpen(false);
  }, []);

  const submitBarcode = useCallback((barcode) => {
    dispatch({
      type: ActionType.SetSpecimen,
      payload: {
        id: barcode,
        labelUsed: "A",
      },
    });
    goToBarcodeResult();
  }, []);

  const onShot = useCallback(async (imageData: string) => {
    const barcodeResult = await detectBarcodeQuagga(imageData);

    if (!barcodeResult || !validateSpecimenId(barcodeResult.code)) {
      return;
    }

    beepRef.current?.play();

    const testTubeImage = await extractBarCodeImage(
      imageData,
      barcodeResult.boundingBox
    );

    dispatch({
      type: ActionType.SetSpecimen,
      payload: {
        id: barcodeResult.code,
        testTubeImage,
        labelUsed: state.currentLabel,
      },
    });

    goToBarcodeResult();
  }, []);

  const next = useCallback(() => {
    openEnterBarcodeDialog();
  }, []);

  const onCameraReady = useCallback(() => {
    setCameraReady(true);
    // NOTE: This if statement will be removed on build by webpack
    if (process.env.NODE_ENV === "development") {
      setShowManualOption(true);
    }
    setTimeout(() => setShowManualOption(true), BARCODE_FALLBACK_TIMER);
  }, []);

  // Capture image every 1000ms for barcode scan.
  // Based on device testing, Samsung Galaxy Tablet A8 can finish `detectBarcodeQuagga` in 1000ms.
  // If we set it to less than 1000ms, call to `detectBarcodeQuagga` might be requested before the previous call completes.
  // In this situation, an exeption will be thrown and detection will fail.
  // for detail: https://github.com/serratus/quaggaJS/issues/135
  // TODO: only enable 1000ms for oral-eze for testing purpose, condition needs to be removed before merging.
  const autoFrameInterval =
    appConfigurationState.workflowId === "oral-eze" ? 1000 : 125;

  return (
    <div className="barcode-scan-wrapper camera-scanner">
      <div className="back-link no-print exit-drug-test__link" onClick={goBack}>
        <Link>{t("common-goBack")}</Link>
      </div>
      <audio ref={beepRef as any} src={beep}></audio>
      <Camera
        mode={CameraMode.Auto}
        onShot={onShot}
        autoFramesInterval={autoFrameInterval}
        onReady={onCameraReady}
        facingMode="environment"
        startButtonText={t("scan-text")}
      />
      {cameraReady && (
        <>
          <div className="overlay">
            <div className="target-wrapper">
              <ScanTarget />
            </div>
          </div>
          <div className="overlay top">
            <Text color="white" fontSize="T500" className="instructions">
              {t("dt-barcode-instruction")}
            </Text>
          </div>
          {showManualOption && (
            <div className="overlay bottom">
              <Row justifyContent="center">
                <Button onClick={next}>{t("dt-barcode-enter_manually")}</Button>
              </Row>
            </div>
          )}
        </>
      )}
      <EnterBarcodeDialog
        isOpen={isEnterBarcodeDialogOpen}
        onClose={closeEnterBarcodeDialog}
        onSubmit={submitBarcode}
      />
    </div>
  );
};
