import { useCallback } from "react";
import { useNavigate } from "react-router";
import { useSaveCurrentUrl } from "./use-save-current-url";

export const useNavigator = (...urls: Array<string>) => {
  const navigate = useNavigate();

  const saveCurrentUrl = useSaveCurrentUrl();

  return urls.map((url: string) => {
    return useCallback(() => {
      saveCurrentUrl(url);
      navigate(url);
    }, [url]);
  });
};
