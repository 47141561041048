import i18n from "i18next";
import { initReactI18next } from "react-i18next";
//import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEN from "../translations/locales/en/translation-en-US.puff.json";
import translationES from "../translations/locales/en/translation-es-US.puff.json";

// // the translations
const resources = {
  en: {
    translation: translationEN.resources,
  },
  es: {
    translation: translationES.resources,
  },
};

i18n
  //.use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: {
      en: ["en"],
      default: ["en"],
    },
    debug: false,
    react: {
      useSuspense: false,
    },
    keySeparator: false,
  });

export default i18n;
