import React from "react";
import { Row } from "@amzn/stencil-react-components/layout";
import {
  Button,
  ButtonProps,
} from "@amzn/stencil-react-components/button";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean;
}

export const LoadingButton = ({ loading, ...props }: LoadingButtonProps) => {
  return (
    <Button disabled={loading} variant={props.variant} onClick={props.onClick}>
      <Row gridGap="1rem" alignItems="center">
        {props.children}
        {loading && <Spinner size={SpinnerSize.Small} />}
      </Row>
    </Button>
  );
};
