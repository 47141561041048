import React, { useCallback, useState } from "react";
import { Button } from "@amzn/stencil-react-components/button";
import { SignatureDialog } from "./dialog";

import { useTranslation } from "react-i18next";

interface SignatureImageProps {
  onSignatureChange?: (imageData: string) => void;
  errorState?: boolean;
}
export const SignatureImage = ({
  onSignatureChange,
  errorState,
}: SignatureImageProps) => {
  const [imageData, setImageData] = useState("");
  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
  const { t } = useTranslation();

  const openSignatureDialog = useCallback(() => {
    setIsSignatureDialogOpen(true);
  }, []);

  const closeSignatureDialog = useCallback(() => {
    setIsSignatureDialogOpen(false);
  }, []);

  const onSubmit = useCallback((imageData: string) => {
    setImageData(imageData);
    onSignatureChange?.(imageData);
  }, []);

  return (
    <>
      {imageData === "" ? (
        <Button isDestructive={errorState} onClick={openSignatureDialog}>
          {/* Draw signature */}
          {t("dt-form-drawSignature")}
        </Button>
      ) : (
        <img
          className="signature-image"
          src={imageData}
          onClick={openSignatureDialog}
        />
      )}
      <SignatureDialog
        isOpen={isSignatureDialogOpen}
        onClose={closeSignatureDialog}
        onSubmit={onSubmit}
      ></SignatureDialog>
    </>
  );
};
