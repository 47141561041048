import {
  URL_DT_CANDIDATE_CONFIRM,
  URL_DT_CANDIDATE_CONSENT,
  URL_DT_VIDEO_PRE,
  URL_DT_TIMER,
  URL_DT_VIDEO_POST,
  URL_DT_GUIDE_START,
  URL_DT_GUIDE_LABEL,
  URL_DT_GUIDE_STICK,
  URL_DT_BARCODE_SCAN_LASER,
  URL_DT_BARCODE_RESULT,
  URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS,
  URL_DT_GUIDE_FINAL,
  URL_DT_FORM,
  URL_CLEANING,
  URL_TY,
  URL_DT_QUANTISAL_PRE,
  URL_DT_QUANTISAL_POST,
} from "./urls";

export interface Milestone {
  label: string;
  steps: WorkflowStep[];
}

export interface WorkflowStep {
  label: string;
}

export const WorkflowStepsOralEze: Milestone[] = [
  {
    label: "workflow-step1",
    steps: [
      {
        label: URL_DT_CANDIDATE_CONFIRM,
      },
    ],
  },
  {
    label: "workflow-step2",
    steps: [
      {
        label: URL_DT_CANDIDATE_CONSENT,
      },
    ],
  },
  {
    label: "workflow-step3",
    steps: [
      {
        label: URL_DT_VIDEO_PRE,
      },
      {
        label: URL_DT_TIMER,
      },
      {
        label: URL_DT_VIDEO_POST,
      },
      {
        label: URL_DT_GUIDE_START,
      },
    ],
  },
  {
    label: "workflow-step4",
    steps: [
      {
        label: URL_DT_GUIDE_LABEL,
      },
      {
        label: URL_DT_GUIDE_STICK,
      },
    ],
  },
  {
    label: "workflow-step5",
    steps: [
      {
        label: URL_DT_BARCODE_SCAN_LASER,
      },
      {
        label: URL_DT_BARCODE_RESULT,
      },
      {
        label: URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS,
      },
    ],
  },
  {
    label: "workflow-step6",
    steps: [
      {
        label: URL_DT_FORM,
      },
      {
        label: URL_DT_GUIDE_FINAL,
      },
    ],
  },
  {
    label: "workflow-step7",
    steps: [
      {
        label: URL_CLEANING,
      },
    ],
  },
  {
    label: "workflow-step8",
    steps: [
      {
        label: URL_TY,
      },
    ],
  },
];

export const WorkflowStepsQuantisal: Milestone[] = [
  {
    label: "workflow-step1",
    steps: [
      {
        label: URL_DT_CANDIDATE_CONFIRM,
      },
    ],
  },
  {
    label: "workflow-step2",
    steps: [
      {
        label: URL_DT_CANDIDATE_CONSENT,
      },
    ],
  },
  {
    label: "workflow-step3",
    steps: [
      {
        label: URL_DT_QUANTISAL_PRE,
      },
      {
        label: URL_DT_TIMER,
      },
      {
        label: URL_DT_QUANTISAL_POST,
      },
      {
        label: URL_DT_GUIDE_START,
      },
    ],
  },
  {
    label: "workflow-step4",
    steps: [
      {
        label: URL_DT_GUIDE_LABEL,
      },
      {
        label: URL_DT_GUIDE_STICK,
      },
    ],
  },
  {
    label: "workflow-step5",
    steps: [
      {
        label: URL_DT_BARCODE_SCAN_LASER,
      },
      {
        label: URL_DT_BARCODE_RESULT,
      },
      {
        label: URL_DT_BARCODE_SCAN_CAM_INSTRUCTIONS,
      },
    ],
  },
  {
    label: "workflow-step6",
    steps: [
      {
        label: URL_DT_FORM,
      },
      {
        label: URL_DT_GUIDE_FINAL,
      },
    ],
  },
  {
    label: "workflow-step7",
    steps: [
      {
        label: URL_CLEANING,
      },
    ],
  },
  {
    label: "workflow-step8",
    steps: [
      {
        label: URL_TY,
      },
    ],
  },
];

export interface WorkflowVendors {
  workflowid: string;
  steps: Milestone[];
}
export const Vendors: WorkflowVendors[] = [
  {
    workflowid: "oral-eze",
    steps: WorkflowStepsOralEze,
  },
  {
    workflowid: "quantisal",
    steps: WorkflowStepsQuantisal,
  },
];
