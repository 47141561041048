import React, { ComponentType, useContext } from "react";
import { WorkflowProgressProps } from "../components/workflow-progress";
import { Milestone, Vendors, WorkflowVendors } from "../config/workflow-steps";
import { Context } from "../store/store";

type ComponentWithWorkflowProgress = ComponentType<WorkflowProgressProps>;

export const withCurrentWorkflowProgress = (
  Component: ComponentWithWorkflowProgress
) => {
  return function CurrentWorkflowProgress(props: any) {
    const { state, appConfigurationState } = useContext(Context);
    const workflowId = appConfigurationState.workflowId;
    const vendorIndex = Vendors.findIndex((workflowVendors: WorkflowVendors) => {
      return workflowVendors.workflowid === workflowId;
    })
    const vendors = Vendors[vendorIndex];

    const currentIndex = vendors.steps.findIndex((milestone: Milestone) => {
      return milestone.steps.some((step) => step.label === state.currentUrl);
    });

    return (
      <Component {...props} steps={vendors.steps} currentIndex={currentIndex} />
    );
  };
};

export const currentWorkflowIndex = () => {
  const { state } = useContext(Context);

  try {
    const findMilestone = function (milestone: Milestone) {
      return milestone.steps.some((step) => step.label === state.currentUrl);
    };

    const milestoneIndex = WorkflowSteps.findIndex(findMilestone);

    const stepIndex = WorkflowSteps[milestoneIndex].steps.findIndex(
      (step) => step.label === state.currentUrl
    );

    return { milestone: milestoneIndex, step: stepIndex };
  } catch (error) {
    return null;
  }
};

export const previousWorkflowIndex = () => {
  const { state, appConfigurationState } = useContext(Context);

  try {
    // const findMilestone = function (milestone: Milestone) {
    //   return milestone.steps.some((step) => step.label === state.currentUrl);
    // };

    // const milestoneIndex = WorkflowSteps.findIndex(findMilestone);

    const vendorIndex = Vendors.findIndex((workflowVendors: WorkflowVendors) => {
      return workflowVendors.workflowid === appConfigurationState.workflowId;
    });

    const vendors = Vendors[vendorIndex];

    const milestoneIndex = vendors.steps.findIndex((milestone: Milestone) => {
      return milestone.steps.some((step) => step.label === state.currentUrl);
    });
    
    const stepIndex = vendors.steps[milestoneIndex].steps.findIndex(
      (step) => step.label === state.currentUrl
    );

    if (stepIndex > 0) {
      return { milestone: milestoneIndex, step: stepIndex - 1 };
    }
    if (stepIndex <= 0 && milestoneIndex > 0)
      return {
        milestone: milestoneIndex - 1,
        step: vendors.steps[milestoneIndex - 1].steps.length - 1,
      };
    else return null;
  } catch (error) {
    return null;
  }
};

export const nextWorkflowIndex = () => {
  const { state, appConfigurationState } = useContext(Context);

  try {
    // const findMilestone = function (milestone: Milestone) {
    //   return milestone.steps.some((step) => step.label === state.currentUrl);
    // };

    // const milestoneIndex = WorkflowSteps.findIndex(findMilestone);

    const vendorIndex = Vendors.findIndex((workflowVendors: WorkflowVendors) => {
      return workflowVendors.workflowid === appConfigurationState.workflowId;
    });

    const vendors = Vendors[vendorIndex];

    const milestoneIndex = vendors.steps.findIndex((milestone: Milestone) => {
      return milestone.steps.some((step) => step.label === state.currentUrl);
    });

    const stepIndex = vendors.steps[milestoneIndex].steps.findIndex(
      (step) => step.label === state.currentUrl
    );

    if (stepIndex + 1 < vendors.steps[milestoneIndex].steps.length - 1) {
      return { milestone: milestoneIndex, step: stepIndex + 1 };
    }
    if (
      stepIndex + 1 >= vendors.steps[milestoneIndex].steps.length - 1 &&
      milestoneIndex + 1 < vendors.steps.length - 1
    )
      return { milestone: milestoneIndex + 1, step: 0 };
    else return null;
  } catch (error) {
    return null;
  }
};

export const currentUrl = () => {
  const { state } = useContext(Context);
  return state.currentUrl;
}

export const previousUrl = () => {
  const { appConfigurationState } = useContext(Context);
  const index = previousWorkflowIndex();
  const vendorIndex = Vendors.findIndex((workflowVendors: WorkflowVendors) => {
    return workflowVendors.workflowid === appConfigurationState.workflowId;
  });

  const vendors = Vendors[vendorIndex];
  if (!index) {
    return "";
  } else {
    return vendors.steps[index.milestone].steps[index.step].label;
  }
};
export const nextUrl = () => {
  const { appConfigurationState } = useContext(Context);
  const index = nextWorkflowIndex();
  const vendorIndex = Vendors.findIndex((workflowVendors: WorkflowVendors) => {
    return workflowVendors.workflowid === appConfigurationState.workflowId;
  });

  const vendors = Vendors[vendorIndex];
  if (!index) {
    return "";
  } else {
    return vendors.steps[index.milestone].steps[index.step].label;
  }
};

export const firstWorkflowStep = () => {
  return WorkflowSteps[0].steps[0].label;
};


