const defaultTextColor = "#22303e";
const positiveColor = "#088752";
const secondaryTextColor = "#65797b";
const labelColor = "#22303e";
const errorColor = "#bb2929";

export {
  defaultTextColor,
  positiveColor,
  secondaryTextColor,
  labelColor,
  errorColor,
};
