import { useContext } from "react";
import { URL_ADOBE_DATA_LAYER_SCRIPT } from "../config/urls";
import { getStage } from "../helpers/stage-info";
import { Context } from "../store/store";
import { addScript } from "../utility/add-script";
import { addMetric } from "../utility/adobe-analytics";

addScript(URL_ADOBE_DATA_LAYER_SCRIPT[getStage()]);

interface CustomEventProps {
  pageName?: string;
  eventName: string;
  props?: any;
}

let __currentPageName = "ecoc page";

export const useAdobeAnalytics = () => {
  const { state } = useContext(Context);
  const candidateId = state.candidate?.candidateId;
  const candidateProp = candidateId ? { candidate: { ID: candidateId } } : {};

  const addPageLoadMetric = (pageName: string) => {
    __currentPageName = pageName;
    addMetric(window, "page load", {
      page: {
        name: pageName,
      },
      ...candidateProp,
    });
  };

  const addCustomEventMetric = ({
    pageName,
    eventName,
    props,
  }: CustomEventProps) => {
    addMetric(window, eventName, {
      page: {
        name: pageName || __currentPageName,
      },
      ...candidateProp,
      ...props,
    });
  };

  const addGoBackEventMetric = () => {
    addCustomEventMetric({ eventName: "back to previous page" });
  };

  return {
    addPageLoadMetric,
    addCustomEventMetric,
    addGoBackEventMetric,
  };
};
