import React, { useContext } from "react";
import { OralEzeInternal } from "../workflow-routings/oral-eze/oral-eze-internal";
import { QuantisalInternal } from "../workflow-routings/quantisal/quantisal-internal";
import { Context } from "../store/store";

export const WorkflowInternalRouteFactory = () => {
  const { appConfigurationState } = useContext(Context);
  const workflowId = appConfigurationState.workflowId;

  if (workflowId === null) {
    return <OralEzeInternal />;
  } else if (workflowId === "oral-eze") {
    return <OralEzeInternal />;
  }
  else if (workflowId === "quantisal") {
    return <QuantisalInternal />;
  }
  return null;
};

