import React, { useCallback, useEffect } from "react";
import { H1} from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { Card } from "../../../components/card";
import "./styles.scss";
import quantisal_step1 from "./quantisal_step1.png";
import quantisal_step2 from "./quantisal_step2.png";
import quantisal_step3 from "./quantisal_step3.png";
import quantisal_step4 from "./quantisal_step4.png";
import quantisal_step5 from "./quantisal_step5.png";
import { nextUrl, previousUrl } from "../../../hoc/with-current-workflow-progress";

export const DTQuantisalPreRoute = () => {
    const [goToTimer, goBack] = useNavigator(
        nextUrl(),
        previousUrl()
    );
    const { t } = useTranslation();
   

    const {
        addPageLoadMetric,
        addCustomEventMetric,
        addGoBackEventMetric,
    } = useAdobeAnalytics();

    useEffect(() => {
        addPageLoadMetric("pre drug test video");
    }, []);

    const back = useCallback(() => {
        addGoBackEventMetric();
        goBack();
    }, [goBack, addCustomEventMetric]);

    const next = useCallback(() => {
        goToTimer();
    }, [goToTimer]);

    return (
        <Col margin="3rem 0 0 0" gridGap="2rem">
            <H1 fontSize="T500">{t("dt-video-pre-title")}</H1>
            <div className="quantisal-pre-label">
                <div className="step1">
                    <Row justifyContent="center">
                        <Card
                            title={t("dt-quantisal-pre-step1-title")}
                            text={t("dt-quantisal-pre-step1")}
                            imageHref={quantisal_step1}
                        />
                    </Row>
                </div>
                <div className="step2">
                    <Card
                        title={t("dt-quantisal-pre-step2-title")}
                        text={t("dt-quantisal-pre-step2")}
                        imageHref={quantisal_step2}
                    />
                </div>
                <div className="step3">
                    <Card
                        title={t("dt-quantisal-pre-step3-title")}
                        text={t("dt-quantisal-pre-step3")}
                        imageHref={quantisal_step3}
                    />
                </div>
                <div className="step4">
                    <Card
                        title={t("dt-quantisal-pre-step4-title")}
                        text={t("dt-quantisal-pre-step4")}
                        imageHref={quantisal_step4}
                    />
                </div> <div className="step5">
                    <Card
                        title={t("dt-quantisal-pre-step5-title")}
                        text={t("dt-quantisal-pre-step5")}
                        imageHref={quantisal_step5}
                    />
                </div>
            </div>



            <Row justifyContent="flex-end" gridGap="1rem" margin="3rem 0 0 0">
                <Button onClick={back}>{t("common-back")}</Button>
                <Button onClick={next} variant={ButtonVariant.Primary}>
                    {t("common-next")}
                </Button>
            </Row>
        </Col>
    );


};

