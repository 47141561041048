import { URL_API_DT_SUBMIT_COC } from "../config/urls";
import { fetcher } from "../helpers/fetcher";

export interface SubmitCoCPayload {
  cocImage: string;
  collectionDateTime: string;
  language: string;
}

export const submitCoC = async (
  candidateId: string,
  accessToken: string,
  drugTestRequestId: string,
  payload: SubmitCoCPayload
) => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("submitCoC", "mocked request", payload);
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
          // reject(new ServerError("", 500));
        }, 1000);
      });
    }
  }

  const url = URL_API_DT_SUBMIT_COC.replace(
    "{candidateId}",
    candidateId
  ).replace("{drugTestRequestId}", drugTestRequestId);

  return fetcher(url, {
    method: "POST",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
