import React, { useCallback, useContext, useEffect, useState } from "react";
import { H1, Text } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Context } from "../../../store/store";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { Countdown } from "../../../components/countdown";
import { Status, StatusIndicator } from "@amzn/stencil-react-components/status-indicator";

import step1Image from "../guide/step1.png";
import step2ImageA from "../guide/step2-a.png";
import step3ImageA from "../guide/step3-a.png";
import step4ImageA from "../guide/step4-a.png";
import step2ImageB from "../guide/step2-b.png";
import step3ImageB from "../guide/step3-b.png";
import step4ImageB from "../guide/step4-b.png";
import step5ImageA from "../guide/step5-a.png";
import wrongImage1A from "../guide/wrong1-a.png";
import wrongImage2A from "../guide/wrong2-a.png";
import step5ImageB from "../guide/step5-b.png";
import wrongImage1B from "../guide/wrong1-b.png";
import wrongImage2B from "../guide/wrong2-b.png";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import {
  nextUrl,
  previousUrl,
} from "../../../hoc/with-current-workflow-progress";
import { isIntegrationTestMode } from "../../../utility/local-storage";

const images = [
  step1Image,
  step2ImageA,
  step3ImageA,
  step4ImageA,
  step2ImageB,
  step3ImageB,
  step4ImageB,
  step5ImageA,
  wrongImage1A,
  wrongImage2A,
  step5ImageB,
  wrongImage1B,
  wrongImage2B,
];

const TIMEOUT = isIntegrationTestMode() ? 1000 : 1000 * 60 * 10;

const preloadImage = (imageSource: any) => {
  const imgEl = document.createElement("img");
  imgEl.src = imageSource;
};

export const DTTimerRoute = () => {
  const [goNext, goBack] = useNavigator(nextUrl(), previousUrl());
  const [started, setStarted] = useState(false);
  const [showNextButton, setShowNextButton] = useState(false);
  const { t } = useTranslation();
  const {
    addPageLoadMetric,
    addCustomEventMetric,
    addGoBackEventMetric,
  } = useAdobeAnalytics();

  const { state, appConfigurationState } = useContext(Context);
  const workflowId = appConfigurationState.workflowId;

  useEffect(() => {
    addPageLoadMetric("drug test timer");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addCustomEventMetric]);

  const indicatorTurnedBlue = useCallback(() => {
    addCustomEventMetric({
      eventName: "timer turned blue on drug test timer page",
    });
    goNext();
  }, [goNext, addCustomEventMetric]);

  const timeIsOver = useCallback(() => {
    state.timerFinished = true;
    setShowNextButton(true);
  }, []);

  const start = useCallback(() => {
    // NOTE: This if statement will be removed on build by webpack
    if (process.env.NODE_ENV === "development") {
      setShowNextButton(true);
    }

    setTimeout(() => {
      setShowNextButton(true);
    }, appConfigurationState.timerBypassLength || TIMEOUT);

    images.forEach(preloadImage);

    setStarted(true);
  }, []);

  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      <H1 fontSize="T500">{t("dt-timer-title")}</H1>
      <Text>{t(`dt-timer-instruction-${workflowId}`)}</Text>
      <StatusIndicator
        status={Status.Information}
        messageText={t("dt-timer-warning")}
      />
      <Row justifyContent="center">
        <Countdown
          running={started}
          fontSize="8rem"
          timer={TIMEOUT}
          onFinished={timeIsOver}
        />
      </Row>
      <Row justifyContent="center" gridGap="1rem">
        <Button onClick={back}>{t("common-back")}</Button>
        {!started && (
          <Button onClick={start} variant={ButtonVariant.Primary}>
            {t("dt-timer-start")}
          </Button>
        )}
        {started && showNextButton && (
          <Button onClick={indicatorTurnedBlue} variant={ButtonVariant.Primary}>
            {t("dt-timer-turnedBlue")}
          </Button>
        )}
      </Row>
    </Col>
  );
};
