import React, { useCallback, useState, useEffect } from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { defaultTextColor } from "../../config/palette";
import { Header } from "../../components/header";
import { H1, Text } from "../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../hooks/use-navigator";
import { URL_TY, URL_DT_GUIDE_FINAL } from "../../config/urls";
import cleaningImage1 from "./cleaning-booth.jpeg";
import cleaningImage2 from "./cleaning-kindle.jpeg";
import cleaningImage3 from "./cleaning-trash.jpeg";
import { useIdleTimer } from "react-idle-timer";
import { ExpireSessionDialog } from "../dt/dialogs/expire-session";
import { useAdobeAnalytics } from "../../hooks/use-adobe-analytics";
import { CurrentWorkflowProgress } from "../../components/workflow-progress";
import "./styles.scss";
import "../../index.scss";
const IDLE_TIMEOUT = 1000 * 60 * 3;
const EXPIRE_TIMEOUT = 1000 * 60 * 2;

export const CleaningRoute = () => {
  const [goToTY, goBack] = useNavigator(URL_TY, URL_DT_GUIDE_FINAL);
  const { t } = useTranslation();
  const [isExpireSessionDialogOpen, setIsExpireSessionDialogOpen] = useState(
    false
  );
  const { addPageLoadMetric, addGoBackEventMetric } = useAdobeAnalytics();
  useEffect(() => {
    addPageLoadMetric("drug test cleaning");
  }, []);

  const next = useCallback(() => {
    goToTY();
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addGoBackEventMetric]);

  const closeExpireSessionDialog = useCallback(() => {
    setIsExpireSessionDialogOpen(false);
  }, []);

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => {
      setIsExpireSessionDialogOpen(true);
    },
  });
  return (
    <PageContainer color={defaultTextColor} minHeight="100vh">
      <Col className="StickyHeader">
        <Header></Header>
        <Col margin="2rem 0 0 0">
          <CurrentWorkflowProgress />
        </Col>
      </Col>
      <Col margin="3rem 0 0 0" gridGap="3rem" alignItems="center">
        <H1 fontSize="T500">{t("cleaning-title")}</H1>
        <div className="cleaning-grid-row">
          <div className="step1Label">
            <Text>{t("cleaning-step1")}</Text>
          </div>
          <div className="step2Label">
            <Text>{t("cleaning-step2")}</Text>
          </div>
          <div className="step3Label">
            <Text>{t("cleaning-step3")}</Text>
          </div>
          <div className="step1Image">
            <img src={cleaningImage1} alt="step1Image" />
          </div>
          <div className="step2Image">
            <img src={cleaningImage2} alt="step2Image" />
          </div>
          <div className="step3Image">
            <img src={cleaningImage3} alt="step3Image" />
          </div>
        </div>
      </Col>
      <Col margin="3rem 0 0 0" gridGap="3rem">
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={back}>{t("common-back")}</Button>
          <Button onClick={next} variant={ButtonVariant.Primary}>
            {t("common-next")}
          </Button>
        </Row>
      </Col>
      <ExpireSessionDialog
        isOpen={isExpireSessionDialogOpen}
        onClose={closeExpireSessionDialog}
        timeout={EXPIRE_TIMEOUT}
      ></ExpireSessionDialog>
    </PageContainer>
  );
};
