import React, { useCallback, useEffect } from "react";
import { H1, Text } from "../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";
import { useAdobeAnalytics } from "../../../hooks/use-adobe-analytics";
import { Card } from "../../../components/card";
import pre_step1 from "./pre_step1.png";
import pre_step2 from "./pre_step2.png";
import pre_step3 from "./pre_step3.png";
import "./styles.scss";
import { nextUrl, previousUrl } from "../../../hoc/with-current-workflow-progress";

export const DTVideoPreRoute = () => {
  const [goToTimer, goBack] = useNavigator(
    nextUrl(),
    previousUrl()
  );
  const { t } = useTranslation();


  const {
    addPageLoadMetric,
    addCustomEventMetric,
    addGoBackEventMetric,
  } = useAdobeAnalytics();

  useEffect(() => {
    addPageLoadMetric("pre drug test video");
  }, []);

  const back = useCallback(() => {
    addGoBackEventMetric();
    goBack();
  }, [goBack, addCustomEventMetric]);

  const next = useCallback(() => {
    goToTimer();
  }, [goToTimer]);


  return (
    <Col margin="3rem 0 0 0" gridGap="2rem">
      <H1 fontSize="T500">{t("dt-video-pre-title")}</H1>
      <div className="video-pre-label">
        <div className="step1">
          <Row justifyContent="center">
            <Card
              title={t("dt-video-pre-step1-title")}
              text={t("dt-video-pre-step1")}
              imageHref={pre_step1}
            />
          </Row>
        </div>
        <div className="step2">
          <Card
            title={t("dt-video-pre-step2-title")}
            text={t("dt-video-pre-step2")}
            imageHref={pre_step2}
          />
        </div>
        <div className="step3">
          <Card
            title={t("dt-video-pre-step3-title")}
            text={t("dt-video-pre-step3")}
            imageHref={pre_step3}
          />
        </div>

      </div>

      <Text textAlign="center">{t("dt-video-instruction1")}</Text>
      <Text textAlign="center">{t("dt-video-instruction2")}</Text>

      <Row justifyContent="flex-end" gridGap="1rem" margin="3rem 0 0 0">
        <Button onClick={back}>{t("common-back")}</Button>
        <Button onClick={next} variant={ButtonVariant.Primary}>
          {t("common-next")}
        </Button>
      </Row>
    </Col>
  );



};

