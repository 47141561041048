import { Action, ActionType, State } from "./state";

const Reducer = (state: State, action: Action) => {
  
  switch (action.type) {
    case ActionType.SetSpecimen:
      return {
        ...state,
        specimen: action.payload,
      };

    case ActionType.SetMonitor:
      return {
        ...state,
        monitor: action.payload,
      };

      // case ActionType.SetWorkFlowId:
      // return {
      //   ...state,
      //   workflowId: action.payload,
      // };

    case ActionType.SetRecruiterToken:
      return {
        ...state,
        recruiterToken: action.payload,
      };

    case ActionType.MonitorSignOut:
      return {
        ...state,
        monitor: undefined,
        candidate: undefined,
        specimen: undefined,
        currentLabel: undefined,
        timerFinished: undefined,
        recruiterToken: undefined,
      };

    case ActionType.SetCandidate:
      return {
        ...state,
        candidate: action.payload,
      };

    case ActionType.UpdatePhoneNumber:
      return {
        ...state,
        candidate: {
          ...state.candidate,
          phoneNumber: action.payload,
        },
      };

    case ActionType.UpdateDateOfBirth:
      return {
        ...state,
        candidate: {
          ...state.candidate,
          dateOfBirth: action.payload,
        },
      };

    case ActionType.ExitDrugTest:
      return {
        ...state,
        candidate: undefined,
        currentUrl: "",
        specimen: undefined,
        currentLabel: undefined,
        timerFinished: undefined
      };

    case ActionType.SetCurrentUrl:
      return {
        ...state,
        currentUrl: action.payload,
      };

    case ActionType.SetCurrentLabel:
      return {
        ...state,
        currentLabel: action.payload,
      };

    case ActionType.ShowExpiredRequestDialog:
      return {
        ...state,
        expiredRequest: action.payload,
      };

    default:
      return state;
  }
};

export default Reducer;

