import {
  LoggerSender,
  LogType,
} from "@amzn/hvh_client_side_error_tracker/dist/logger-sender";
import { fetcher } from "../helpers/fetcher";

const createOnFlush = (url: string) => async (metrics: any) => {
  try {
    await fetcher(url, {
      method: "POST",
      body: JSON.stringify(metrics),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return true;
  } catch (e) {
    return false;
  }
};

export class MetricsSender {
  loggerSender: LoggerSender;

  constructor(url: string) {
    this.loggerSender = new LoggerSender({
      onFlush: createOnFlush(url),
      urlResolver: () => Promise.resolve(url),
    });
  }

  push(metric: any) {
    // TODO: Uncomment when metrics handler is implemented on the backend
    // this.loggerSender.push(JSON.stringify(metric), LogType.Metric);
    console.warn(
      metric,
      LogType.Metric,
      "metrics sender is not implemented on the backend"
    );
  }
}
