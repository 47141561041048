import { init } from "@amzn/hvh_client_side_error_tracker";
import { OnFlush } from "@amzn/hvh_client_side_error_tracker/dist/logger-sender";
import { URL_API_LOGGER } from "./config/urls";
import { fetcher } from "./helpers/fetcher";
import { isTokenExpired } from "./utility/recruiter-token";

let activeCandidateId: undefined | string = undefined;

const healthCheck = (): boolean => {
  try {
    const healthCheckStatus = !isTokenExpired();
    return healthCheckStatus;
  } catch (e) {
    return false;
  }
};

const onFlush: OnFlush = async (logs, url) => {
  const extendedLogs = activeCandidateId
    ? logs.map((log) => {
        return {
          ...log,
          candidateId: activeCandidateId,
        };
      })
    : logs;

  try {
    await fetcher(url, {
      method: "POST",
      body: JSON.stringify({
        logs: extendedLogs,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    return true;
  } catch (e) {
    return false;
  }
};

export const enableErrorTracking = () => {
  init({
    urlResolver: () => Promise.resolve(URL_API_LOGGER),
    healthCheck,
    onFlush,
  });
};

export const setActiveCandidateId = (candidateId?: string) => {
  activeCandidateId = candidateId;
};

export const clearActiveCandidateId = () => {
  activeCandidateId = undefined;
};
