import React, { useCallback, useContext, useRef, useState } from "react";
import { Camera, CameraMode, OutputImageFormat } from "@amzn/hvh-camera-ui";
import { Link, Text } from "../../components/typography";
import { useNavigator } from "../../hooks/use-navigator";
import { useTranslation } from "react-i18next";
import { ActionType } from "../../store/state";
import { Context } from "../../store/store";
import beep from "../../media/beep.mp3";
import jsQR from "jsqr";
import { URL_CANDIDATE_LOG_IN, URL_MONITOR_SIGN_IN } from "../../config/urls";
import { ScanTarget } from "../../components/scan-target";
import "./styles.scss";
import { Header } from "../../components/header";
import { saveRecruiterToken } from "../../utility/recruiter-token";

export const MonitorScanQRRoute = () => {
  const { dispatch } = useContext(Context);
  const beepRef = useRef<HTMLAudioElement>();
  const [goToCandidateLogIn, goBack] = useNavigator(
    URL_CANDIDATE_LOG_IN,
    URL_MONITOR_SIGN_IN
  );
  const [cameraReady, setCameraReady] = useState(false);
  const { t } = useTranslation();

  const onShot = useCallback(async (imageData: ImageData) => {
    let code = jsQR(imageData.data, imageData.width, imageData.height, {
      inversionAttempts: "dontInvert",
    });

    // NOTE: This if statement will be removed on build by webpack
    if (process.env.NODE_ENV === "development") {
      if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
        code = {
          data:
            "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJyZWNydWl0ZXJOYW1lIjoiV2FsdGVyIFdoaXRlIiwiaWF0IjoxNjIwNzQxMTkwLCJleHAiOjUxMjMxMjMxMjMsImp0aSI6IjAwZWE3MmQ4LWM5ZDYtNGU4MS05OTIwLWJlYjRhYTdiOTJiNSJ9.NYQjoe1d5DwZljDeBlJMACxLsY91fvy__6Eb3vfJrOI",
        };
      }
    }

    if (!code) {
      return;
    }

    beepRef.current?.play();

    dispatch({
      type: ActionType.SetRecruiterToken,
      payload: code.data,
    });

    saveRecruiterToken(code.data);

    goToCandidateLogIn();
  }, []);

  const onCameraReady = useCallback(() => {
    setCameraReady(true);
  }, []);

  return (
    <>
      <div className="floating-header">
        <Header>
          <span className="no-print exit-drug-test__link" onClick={goBack}>
            <Link>{t("common-goBack")}</Link>
          </span>
        </Header>
      </div>
      <audio ref={beepRef as any} src={beep}></audio>
      <Camera
        mode={CameraMode.Auto}
        onShot={onShot}
        autoFramesInterval={250}
        onReady={onCameraReady}
        facingMode="environment"
        outputFormat={OutputImageFormat.ImageData}
        startButtonText={t("scan-text")}
      />
      {cameraReady && (
        <>
          <div className="overlay">
            <div className="target-wrapper">
              <ScanTarget />
            </div>
          </div>
          <div className="overlay top">
            <Text color="white" fontSize="T500">
              {t("monitor-signIn-scanQrInstruction")}
            </Text>
          </div>
        </>
      )}
    </>
  );
};
