import { Col } from "@amzn/stencil-react-components/layout";
import {
  MessageBanner,
  MessageBannerType,
} from "@amzn/stencil-react-components/message-banner";
import React, { useState } from "react";

interface ErrorBannerProps {
  errorMessage: string;
}

const ErrorBanner = ({ errorMessage }: ErrorBannerProps) => {
  if (!errorMessage) {
    return null;
  }
  return (
    <Col alignItems="stretch" width="100%">
      <MessageBanner type={MessageBannerType.Error}>
        {errorMessage}
      </MessageBanner>
    </Col>
  );
};

export const useErrorBanner = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const errorBanner = <ErrorBanner errorMessage={errorMessage}></ErrorBanner>;
  return {
    setErrorMessage,
    errorBanner,
    hasError: !!errorMessage,
  };
};
