import { saveCsrfToken } from "@amzn/hvh_simple_hire_common/dist/csrf/client";
import { CSRF_KEY } from "@amzn/hvh_simple_hire_common/dist/csrf/common";
import { URL_API_CSRF } from "../config/urls";
import { fetcher } from "./fetcher";

export const requestCsrf = () => {
  window.ASH_ECOC_csrfPromise =
    window.ASH_ECOC_csrfPromise || fetcher(URL_API_CSRF);

  window.ASH_ECOC_csrfPromise.then((response) => {
    saveCsrfToken(response[CSRF_KEY]);
  });
};
