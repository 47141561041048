import React from "react";
import { Row } from "@amzn/stencil-react-components/layout";
import { Link } from "@amzn/stencil-react-components/link";
import logo from "./logo.png";
import "./styles.scss";

type HeaderProps = React.PropsWithChildren<{
  href?: string;
}>;

export const Header = ({ href, children }: HeaderProps) => {
  return (
    <Row alignItems="center" justifyContent="space-between">
      <Link href={href}>
        <img width="150px" src={logo} alt="Amazon" />
      </Link>
      {children}
    </Row>
  );
};

export const Logo = () => {
  return (
    <Row alignItems="center" justifyContent="center">
      <img src={logo} alt="Amazon" className="logo" />
    </Row>
  );
};
