import { URL_API_METRIC } from "../config/urls";
import { MetricsSender } from "../utility/metrics-sender";

const metricSender = new MetricsSender(URL_API_METRIC);

export const useMetrics = () => {
  const sendMetric = async (data: Record<string, any>) => {
    metricSender.push(data);
  };

  return {
    sendMetric,
  };
};
