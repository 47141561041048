import dayjs from "dayjs";
import PhoneNumber from "awesome-phonenumber";

const phoneTailRegex = /^[0-9]{4}\b/;
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const specimenIdRegex = /^AMZ[0-9]{8}$/;
const specimenIdDigitsRegex = /^[0-9]{8}$/;
const MIN_AGE = 18;
const MAX_AGE = 150;
const DATE_FORMAT = "MM/DD/YYYY";

export const validatePhoneTail = (value: string) => {
  return phoneTailRegex.test(value);
};

export const validateEmail = (value: string) => {
  return emailRegex.test(value);
};

export const validateDateOfBirth = (value: string) => {
  if (value.length !== DATE_FORMAT.length) {
    return false;
  }
  const date = dayjs(value, DATE_FORMAT);
  if (date.format(DATE_FORMAT) !== value) {
    return false;
  }
  const year = date.year();
  if (Number.isNaN(year)) {
    return false;
  }
  const currentYear = dayjs().year();
  const tooYoung = year + MIN_AGE > currentYear;
  const tooOld = year + MAX_AGE < currentYear;
  return !tooYoung && !tooOld;
};

export const validatePhone = (phoneNumber: string) => {
  const result = new PhoneNumber(phoneNumber, "US");
  if (!result.isPossible()) {
    return false;
  }

  const digitCount = phoneNumber.match(/\d/g)?.length || 0;
  if (digitCount != 10) {
    return false;
  }

  return result.getNumber("significant");
};

export const validatePhonesMatch = (
  phoneNumber1: string,
  phoneNumber2: string
) => {
  const result1 = validatePhone(phoneNumber1);
  const result2 = validatePhone(phoneNumber2);
  return !!(result1 && result2 && result1 === result2);
};

export const validateSpecimenId = (code: string) => {
  return specimenIdRegex.test(code);
};

export const validateSpecimenIdDigits = (code: string) => {
  return specimenIdDigitsRegex.test(code);
};
