import { useCallback, useContext } from "react";
import { ActionType } from "../store/state";
import { Context } from "../store/store";

export const useSaveCurrentUrl = () => {
  const { dispatch } = useContext(Context);

  return useCallback(
    (url: string) => {
      dispatch({
        type: ActionType.SetCurrentUrl,
        payload: url,
      });
    },
    [dispatch]
  );
};
