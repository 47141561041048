import React, { useCallback } from "react";
import { URL_DT_EXIT } from "../../../config/urls";
import { H2, Text } from "../../../components/typography";
import { useTranslation } from "react-i18next";
import { Modal } from "../../../components/modal";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../../hooks/use-navigator";

interface ExpireRequestDialogProps {
  isOpen: boolean;
}

export const ExpireRequestDialog = ({ isOpen }: ExpireRequestDialogProps) => {
  const [goToExitDrugTest] = useNavigator(URL_DT_EXIT);
  const { t } = useTranslation();

  const exitDrugTest = useCallback(() => {
    goToExitDrugTest();
  }, []);

  return (
    <Modal isOpen={isOpen} close={exitDrugTest}>
      <Col gridGap="2rem">
        <H2>{t("dialog-expireRequest-title")}</H2>
        <Row>
          <Text>{t("dialog-expireRequest-message")}</Text>
        </Row>
        <Row justifyContent="flex-end" gridGap="1rem">
          <Button onClick={exitDrugTest} variant={ButtonVariant.Primary}>
            {t("dialog-expireRequest-confirm")}
          </Button>
        </Row>
      </Col>
    </Modal>
  );
};
