import {
  convertImage,
  cropImage,
  OutputImageFormat,
} from "@amzn/hvh-camera-ui";

const MARGIN = 0;

export const extractBarCodeImage = async (
  imageData: string,
  box: any
): Promise<string> => {
  const imageEl = (await convertImage(
    imageData,
    OutputImageFormat.ImageElement
  )) as HTMLImageElement;
  let left = box.left - MARGIN;
  let top = box.top - MARGIN;
  let right = box.right + MARGIN;
  let bottom = box.bottom + MARGIN;

  left = Math.max(0, left);
  top = Math.max(0, top);
  right = Math.min(imageEl.width, right);
  bottom = Math.min(imageEl.height, bottom);

  let boundingBox = {
    x: left,
    y: top,
    width: right - left,
    height: bottom - top,
  };

  boundingBox = {
    x: 0,
    y: 0,
    width: imageEl.width,
    height: imageEl.height,
  };

  const croppedImage = (await cropImage(
    imageData,
    boundingBox,
    OutputImageFormat.Base64
  )) as string;
  return croppedImage;
};
