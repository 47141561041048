import React, { useCallback, useEffect } from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { defaultTextColor } from "../../config/palette";
import { Header } from "../../components/header";
import { H1 } from "../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { useTranslation } from "react-i18next";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { useNavigator } from "../../hooks/use-navigator";
import { URL_DT_EXIT } from "../../config/urls";
import { useIdleTimer } from "react-idle-timer";
import { useAdobeAnalytics } from "../../hooks/use-adobe-analytics";
import { CurrentWorkflowProgress } from "../../components/workflow-progress";
import "../../index.scss";

const IDLE_TIMEOUT = 1000 * 60 * 1;

export const TYRoute = () => {
  const [goToDTExit] = useNavigator(URL_DT_EXIT);
  const { t } = useTranslation();
  const { addPageLoadMetric, addCustomEventMetric } = useAdobeAnalytics();

  useEffect(() => {
    addPageLoadMetric("drug test thank you");
  }, []);

  const next = useCallback(() => {
    goToDTExit();
  }, []);

  useIdleTimer({
    timeout: IDLE_TIMEOUT,
    onIdle: () => {
      addCustomEventMetric({ eventName: "exit drug test idle" });
      goToDTExit();
    },
  });
    return (
      <PageContainer color={defaultTextColor} minHeight="100vh">
        <Col className="StickyHeader">
          <Header></Header>
          <Col margin="2rem 0 0 0">
            <CurrentWorkflowProgress />
          </Col>
        </Col>
        <Col margin="3rem 0 0 0" gridGap="3rem" alignItems="center">
          <H1 fontSize="T500">{t("ty-title")}</H1>
          <Row>
            <Button onClick={next} variant={ButtonVariant.Primary}>
              {t("common-close")}
            </Button>
          </Row>
        </Col>
      </PageContainer>
    );
  
};
