import { URL_API_DT_UPDATE_DATE_OF_BIRTH } from "../config/urls";
import { fetcher } from "../helpers/fetcher";

export interface UpdateDateOfBirthPayload {
  dateOfBirth: string;
}

export const updateDateOfBirth = async (
  candidateId: string,
  accessToken: string,
  drugTestRequestId: string,
  payload: UpdateDateOfBirthPayload
) => {
  // NOTE: This if statement will be removed on build by webpack
  if (process.env.NODE_ENV === "development") {
    if (process.env.REACT_APP_USE_MOCK_REQUESTS === "true") {
      console.warn("updateDateOfBirth", "mocked request", payload);
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
          // reject(new ServerError("", 500));
        }, 1000);
      });
    }
  }

  const url = URL_API_DT_UPDATE_DATE_OF_BIRTH.replace(
    "{candidateId}",
    candidateId
  ).replace("{drugTestRequestId}", drugTestRequestId);

  return fetcher(url, {
    method: "PATCH",
    body: JSON.stringify(payload),
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
