import React from "react";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { Col } from "@amzn/stencil-react-components/layout";
import { defaultTextColor } from "../../config/palette";

interface ErrorBoundaryProps {
  children: React.ReactNode;
  errorMessage?: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch() {
    // TBD: Log error using Client Side Error Tracker: https://code.amazon.com/packages/HvhClientSideErrorTracker/trees/mainline
  }

  render() {
    if (!this.state.hasError) {
      return this.props.children;
    }

    return (
      <Col height="100vh">
        <PageContainer color={defaultTextColor} height="100%">
          <Col alignItems="center" justifyContent="center" height="100%">
            {this.props.errorMessage}
          </Col>
        </PageContainer>
      </Col>
    );
  }
}
